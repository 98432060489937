import { isBefore } from "date-fns/isBefore";
import { addDays } from "date-fns/addDays";
import { addHours } from "date-fns/addHours";
import { differenceInMinutes } from "date-fns/differenceInMinutes";
import { differenceInHours } from "date-fns/differenceInHours";
import { differenceInDays } from "date-fns/differenceInDays";
import { differenceInSeconds } from "date-fns/differenceInSeconds";
import { format } from "date-fns-tz";
import { translation } from "albertine-shared-web";
import { Conversation } from "../../../lmt/src/common/types/Conversation";
import { DeadlineStep } from "../../../lmt/src/common/types/Deadline";
import { Message } from "../../../lmt/src/common/types/Message";
import { MemberRequest } from "../../../lmt/src/common/types/Request";
import { RequestStatus } from "../../../lmt/src/common/types/RequestStatus";
import { AvatarType } from "../components/Avatar";
import { fromTimestampToDate } from "./timestamp.util";

const relevantDeadlines: DeadlineStep[] = [
    "send_proposal",
    "send_booking_confirmation",
    "send_status_update",
    "wait_for_supplier_response",
];

export function getUnreadMessagesCount(
    conversation: Conversation,
    request: MemberRequest | undefined,
): number {
    if (request) {
        return (conversation.unreadMessagesByMember || []).filter(
            (it) => it.request === request.id,
        ).length;
    }

    return (conversation.unreadMessagesByMember || []).filter(
        (it) => !it.request,
    ).length;
}

export function getLastMessageSender(
    request: MemberRequest,
    lastMessage: Message | undefined,
): {
    avatarType: AvatarType;
    senderId: string;
    senderFullName: string;
} {
    if (request.status === "new") {
        return {
            avatarType: "albertine",
            senderId: "AlbertineServiceAgent",
            senderFullName: "Albertine",
        };
    }

    if (
        relevantDeadlines.some((it) => it === request.nextDeadlineStep) &&
        request.assignedTo &&
        request.assignedToName
    ) {
        return {
            avatarType: "agent",
            senderId: request.assignedTo,
            senderFullName: request.assignedToName,
        };
    }

    if (lastMessage) {
        const avatarType: AvatarType = lastMessage.sentByAgent
            ? "agent"
            : "member";

        return {
            avatarType,
            senderId: lastMessage.sentBy,
            senderFullName: lastMessage.senderName,
        };
    }

    return {
        avatarType: "albertine",
        senderId: "AlbertineServiceAgent",
        senderFullName: "Albertine",
    };
}

export function shouldShowUnreadBadge(
    conversation: Conversation,
    request: MemberRequest,
    lastMessage: Message | undefined,
): { showUnreadCount: boolean; unreadCount: number } {
    const unreadCount = getUnreadMessagesCount(conversation, request);

    if (unreadCount !== 0) {
        return {
            showUnreadCount: true,
            unreadCount,
        };
    }

    if (
        lastMessage?.sentByAgent &&
        request.nextDeadlineStep === "wait_for_member_response"
    ) {
        return {
            showUnreadCount: true,
            unreadCount,
        };
    }

    return {
        showUnreadCount: false,
        unreadCount: 0,
    };
}

export function filterMemberToReview(
    conversation: Conversation,
    requests: MemberRequest[],
) {
    return requests.filter((request) => {
        const unreadCount = getUnreadMessagesCount(conversation, request);

        return (
            request.nextDeadlineStep === "wait_for_member_response" ||
            unreadCount !== 0
        );
    });
}

export function filterInProgress(
    conversation: Conversation,
    requests: MemberRequest[],
) {
    const possibleStatuses: RequestStatus[] = ["new", "open", "confirmed"];

    return requests
        .filter((request) =>
            possibleStatuses.some((status) => status === request.status),
        )
        .filter(
            (request) =>
                request.nextDeadlineStep !== "wait_for_member_response",
        )
        .filter(
            (request) => getUnreadMessagesCount(conversation, request) === 0,
        );
}

export function filterClosed(requests: MemberRequest[]) {
    const possibleStatuses: RequestStatus[] = ["closed"];

    return requests.filter((request) =>
        possibleStatuses.some((status) => status === request.status),
    );
}

export function formatDeadline(
    request: MemberRequest,
    now: Date,
): string | undefined {
    const deadline = request.nextDeadlineAt
        ? fromTimestampToDate(request.nextDeadlineAt)
        : undefined;

    if (
        deadline &&
        relevantDeadlines.some((it) => it === request.nextDeadlineStep)
    ) {
        if (isBefore(deadline, now)) {
            return translation("conversations__deadline-overdue");
        }

        const dayFromNow = addDays(now, 1);

        if (isBefore(deadline, dayFromNow)) {
            const anHourFromNow = addHours(now, 1);

            if (isBefore(deadline, anHourFromNow)) {
                const minutes = differenceInMinutes(deadline, now);
                const remainingSeconds =
                    differenceInSeconds(deadline, now) % 60;
                const roundedMinutes =
                    remainingSeconds <= 30 ? minutes : minutes + 1;

                return translation("conversations__deadline-minutes", {
                    count: roundedMinutes,
                });
            }

            const hours = differenceInHours(deadline, now);
            const remainingMinutes = differenceInMinutes(deadline, now) % 60;
            const roundedHours = remainingMinutes <= 30 ? hours : hours + 1;
            return translation("conversations__deadline-hours", {
                count: roundedHours,
            });
        }

        const weekFromNow = addDays(now, 7);

        if (isBefore(deadline, weekFromNow)) {
            const days = differenceInDays(deadline, now);
            const remainingHours = differenceInHours(deadline, now) % 24;
            const roundedDays = remainingHours <= 30 ? days : days + 1;
            return translation("conversations__deadline-days", {
                count: roundedDays,
            });
        }

        const isSameYear = deadline.getFullYear() === now.getFullYear();

        return format(deadline, `E, d MMM${isSameYear ? "" : " yyyy"}`);
    }

    return undefined;
}

export function conversationHasUnreadMessages(
    conversation: Conversation,
    request: string | undefined,
): boolean {
    if (!conversation.unreadMessagesByMember) return false;
    if (conversation.unreadMessagesByMember.length === 0) return false;

    if (request) {
        return conversation.unreadMessagesByMember.some(
            (it) => it.request === request,
        );
    }
    return true;
}
