import { Lib, useAlbertineTranslation, useTenant } from "albertine-shared-web";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import Screen from "./Screen";
import {
    getCurrentNotificationPermission,
    requestNotificationPermission,
} from "../api/notifications";
import { useRoutes } from "../routes";
import Permission from "../components/Permission";
import "./NotificationPermission.css";

function NotificationPermission() {
    const t = useAlbertineTranslation();
    const navigate = useNavigate();
    const { url } = useRoutes();
    const { tenant } = useTenant();

    useEffect(() => {
        async function tryRedirect() {
            const permission = await getCurrentNotificationPermission();

            if (permission === "granted" || permission === "denied") {
                navigate(url.bookings);
            }
        }

        const interval = setInterval(tryRedirect, 1500);
        tryRedirect();

        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Screen.StartBg01
            className="notification_permission__container"
            textColorInversable={tenant === "hugoboss"}
        >
            <Lib.Flex.Column gap="value32">
                <Permission
                    tagText={t("notifications__tag")}
                    titleText={t("notifications__title")}
                    descriptionText={t("notifications__description")}
                    buttonText={t("notifications__button")}
                    onButton={requestNotificationPermission}
                />
            </Lib.Flex.Column>
        </Screen.StartBg01>
    );
}

export default NotificationPermission;
