import React from "react";
import { SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Lib } from "albertine-shared-web";
import { DynamicTheme } from "../../../lmt/src/common/types/DynamicTheme";
import "./CityGuide.css";
import AlbertineSwiper from "./AlbertineSwiper";

type Props = {
    cities: DynamicTheme[];
};

function CityGuide(props: Props) {
    const { cities } = props;

    return (
        <Lib.Flex.Row>
            <AlbertineSwiper
                spaceBetween={12}
                breakpoints={{
                    720: {
                        spaceBetween: 16,
                    },
                }}
            >
                {cities.map((city) => (
                    <SwiperSlide
                        key={city.key}
                        className="web-lib__cityguide-item"
                    >
                        {city.image && (
                            <Lib.RemoteImage.Rounded
                                src={city.image}
                                alt={city.name}
                            />
                        )}
                        <div className="text-color-inversable web-lib__cityguide-city">
                            <Lib.Heading.H6.Bold>
                                {city.name}
                            </Lib.Heading.H6.Bold>
                        </div>
                    </SwiperSlide>
                ))}
            </AlbertineSwiper>
        </Lib.Flex.Row>
    );
}

export default CityGuide;
