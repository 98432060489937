import React, { useEffect, useState } from "react";
import { Lib, useAlbertineTranslation } from "albertine-shared-web";
import "./OnboardingCommonStyles.css";
import { useOutletContext } from "react-router-dom";
import Transition from "./OnboardingTransition";
import {
    HasUsedConciergeAnswer,
    HasUsedConciergeFor,
} from "../../../../lmt/src/common/types/OnboardingPreferences";
import { OnboardingOutletContextType } from "../../types/OnboardingOutlet";
import { logError } from "../../error";

function OnboardConciergeExperience() {
    const t = useAlbertineTranslation();
    const [step, setStep] = useState<number>(0);
    const {
        member,
        updateMemberPreferences,
        setProgress,
        setNextStep,
        setIsLoading,
    } = useOutletContext<OnboardingOutletContextType>();

    useEffect(() => {
        setProgress(60);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const sendAnswer = async (
        answer: HasUsedConciergeAnswer,
        reason?: HasUsedConciergeFor | string,
    ): Promise<boolean> => {
        setIsLoading(true);
        const preferences = {
            onboarding: {
                hasUsedConcierge: answer,
                hasUsedConciergeFor: reason ?? null,
            },
        };

        try {
            await updateMemberPreferences(preferences, member!);
            setNextStep("travelPlans");
            return true;
        } catch (error) {
            logError("updateMemberPreferences", error);
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Lib.Flex.Column>
            {step > 0 && (
                <Lib.Button.Ghost
                    className="onboarding-back-button"
                    onClick={() => setStep(step - 1)}
                >
                    <Lib.Icon.ArrowBack.Medium />
                </Lib.Button.Ghost>
            )}

            <Lib.Flex.Column>
                {step === 0 && (
                    <Transition>
                        <Lib.Flex.Column gap="value24">
                            <Lib.Flex.Column gap="value8">
                                <Lib.Tag.Primary>
                                    {t("onboarding__concierge-exp__prior-exp")}
                                </Lib.Tag.Primary>

                                <Lib.Paragraph.XXLarge.Bold>
                                    {t("onboarding__concierge-exp__question")}
                                </Lib.Paragraph.XXLarge.Bold>
                            </Lib.Flex.Column>
                            <Lib.Flex.Column gap="value8">
                                <Lib.Button.Onboarding
                                    className="onboarding__concierge-experience__option "
                                    onClick={() => setStep(step + 1)}
                                >
                                    {t("onboarding__concierge-exp__yes")}
                                </Lib.Button.Onboarding>
                                <Lib.Button.Onboarding
                                    className="onboarding__concierge-experience__option "
                                    onClick={() => sendAnswer("no")}
                                >
                                    {t("onboarding__concierge-exp__no")}
                                </Lib.Button.Onboarding>
                                <Lib.Button.Onboarding
                                    onClick={() => sendAnswer("maybe")}
                                >
                                    {t("onboarding__concierge-exp__not-sure")}
                                </Lib.Button.Onboarding>
                            </Lib.Flex.Column>
                        </Lib.Flex.Column>
                    </Transition>
                )}

                {step === 1 && (
                    <Transition>
                        <Lib.Flex.Column gap="value24">
                            <Lib.Flex.Column gap="value8">
                                <Lib.Tag.Primary>
                                    {t("onboarding__concierge-exp__prior-exp")}
                                </Lib.Tag.Primary>

                                <Lib.Paragraph.XXLarge.Bold className="onboarding__concierge-experience__text">
                                    {t(
                                        "onboarding__concierge-exp__why-have-you-used",
                                    )}
                                </Lib.Paragraph.XXLarge.Bold>
                            </Lib.Flex.Column>
                            <Lib.Flex.Column gap="value8">
                                <Lib.Button.Onboarding
                                    onClick={() =>
                                        sendAnswer("yes", "saveTime")
                                    }
                                >
                                    {t(
                                        "onboarding__concierge-exp__saving-time",
                                    )}
                                </Lib.Button.Onboarding>

                                <Lib.Button.Onboarding
                                    onClick={() =>
                                        sendAnswer(
                                            "yes",
                                            "discoverNewExperiences",
                                        )
                                    }
                                >
                                    {t(
                                        "onboarding__concierge-exp__discovering-new-exp",
                                    )}
                                </Lib.Button.Onboarding>

                                <Lib.Button.Onboarding
                                    onClick={() =>
                                        sendAnswer("yes", "accessBenefits")
                                    }
                                >
                                    {t("onboarding__concierge-exp__benefits")}
                                </Lib.Button.Onboarding>

                                <Lib.Button.Onboarding
                                    onClick={() => setStep(step + 1)}
                                >
                                    {t(
                                        "onboarding__concierge-exp__something-else",
                                    )}
                                </Lib.Button.Onboarding>
                            </Lib.Flex.Column>
                        </Lib.Flex.Column>
                    </Transition>
                )}

                {step === 2 && (
                    <Transition>
                        <Lib.Flex.Column gap="value24">
                            <Lib.Flex.Column gap="value8">
                                <Lib.Tag.Primary>
                                    {t("onboarding__concierge-exp__prior-exp")}
                                </Lib.Tag.Primary>

                                <Lib.Paragraph.XXLarge.Bold className="onboarding__concierge-experience__text">
                                    {t(
                                        "onboarding__concierge-exp__why-have-you-used",
                                    )}
                                </Lib.Paragraph.XXLarge.Bold>

                                <Lib.Paragraph.Small.Reqular>
                                    {t(
                                        "onboarding__concierge-exp__main-benefit",
                                    )}
                                </Lib.Paragraph.Small.Reqular>
                            </Lib.Flex.Column>
                            <Lib.Flex.Column>
                                <Lib.TextAreaInput
                                    placeholder="concierge experience"
                                    onSend={(query: string) =>
                                        sendAnswer(
                                            "yes",
                                            `Something Else: ${query}`,
                                        )
                                    }
                                    isActive
                                />
                            </Lib.Flex.Column>
                        </Lib.Flex.Column>
                    </Transition>
                )}
            </Lib.Flex.Column>
        </Lib.Flex.Column>
    );
}

export default OnboardConciergeExperience;
