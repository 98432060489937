import React, { useEffect, useState } from "react";
import { memberGetProposal } from "../api/firestore";
import { MemberGetProposalResponse } from "../../../lmt/src/common/types/Request";
import { logError } from "../error";

type Props = {
    requestId: string;
    children: (
        proposal: MemberGetProposalResponse | undefined,
    ) => React.ReactNode;
};

function LoadProposal(props: Props) {
    const { requestId, children } = props;
    const [proposal, setProposal] = useState<MemberGetProposalResponse>();

    useEffect(() => {
        if (!requestId) return undefined;

        console.log("Loading proposal", requestId);

        const load = async () => {
            try {
                const response = await memberGetProposal(requestId);
                setProposal(response.data);
            } catch (error) {
                logError("LoadProposal", error);
                setProposal(undefined);
            }
        };

        load();

        return () => setProposal(undefined);
    }, [requestId]);

    return children(proposal);
}

export default LoadProposal;
