import {
    collection,
    doc,
    limit,
    orderBy,
    query,
    startAfter,
    where,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { ref } from "firebase/storage";
import { db, functions, storage } from "../Firebase";
import { MemberSendMessagePayload } from "../../../lmt/src/common/types/Message";
import { AllowedMemberUpdates } from "../../../lmt/src/common/types/MemberData";
import {
    MemberGetProposalPayload,
    MemberGetProposalResponse,
} from "../../../lmt/src/common/types/Request";
import { MarkConversationMessagesReadPayload } from "../../../lmt/src/common/types/Conversation";
import { PreferencesPayload } from "../../../lmt/src/common/types/PreferencesPayload";
import { Member } from "../../../lmt/src/common/types/Member";

export const membersCollection = "members";
export const conversationsCollection = "conversations";
export const messagesSubCollection = "messages";
export const requestsSubCollection = "requests";
export const bookingsSubCollection = "bookings";

export const memberDocument = (memberId: string) =>
    doc(db, membersCollection, memberId);

export const conversationDocument = (conversationId: string) =>
    doc(db, conversationsCollection, conversationId);

export const conversationMessagesCollection = (conversationId: string) =>
    `${conversationsCollection}/${conversationId}/${messagesSubCollection}`;

export const conversationMessageDocument = (
    conversationId: string,
    messageId: string,
) => doc(db, conversationMessagesCollection(conversationId), messageId);

export const memberRequestsCollection = (memberId: string) =>
    `${membersCollection}/${memberId}/${requestsSubCollection}`;

export const memberRequestDocument = (memberId: string, requestId: string) =>
    doc(db, memberRequestsCollection(memberId), requestId);

export const agentAvatarThumbnailRef = (agentId: string) =>
    ref(storage, `agent/avatar/thumbnail/${agentId}`);

export const memberAvatarThumbnailRef = (memberId: string) =>
    ref(storage, `member/avatar/thumbnail/${memberId}`);

export const queryConversationMessages = (
    conversation: string,
    request?: string,
    startAfterSentAt?: Date,
    itemLimit?: number,
) =>
    query(
        collection(db, conversationMessagesCollection(conversation)),
        ...(request ? [where("request", "==", request)] : []),
        orderBy("sentAt", "desc"),
        ...(startAfterSentAt ? [startAfter(startAfterSentAt)] : []),
        ...(itemLimit ? [limit(itemLimit)] : []),
    );

export const queryNewConversationMessages = (
    conversation: string,
    request?: string,
) =>
    query(
        collection(db, conversationMessagesCollection(conversation)),
        ...(request ? [where("request", "==", request)] : []),
        orderBy("sentAt", "desc"),
        where("sentAt", ">", new Date()),
    );

export const queryLastMessageInRequest = (
    conversation: string,
    request: string,
) =>
    query(
        collection(db, conversationMessagesCollection(conversation)),
        where("request", "==", request),
        orderBy("sentAt", "desc"),
        limit(1),
    );

export const queryMemberRequests = (memberId: string) =>
    query(
        collection(db, memberRequestsCollection(memberId)),
        orderBy("createdAt", "desc"),
    );

export async function memberSendMessage(payload: MemberSendMessagePayload) {
    return httpsCallable<MemberSendMessagePayload, { success: boolean }>(
        functions,
        "memberSendMessage",
    )(payload);
}

export async function updateMemberData(updates: AllowedMemberUpdates) {
    return httpsCallable(functions, "memberUpdateMemberData")(updates);
}

export const bookingsCollection = (memberId: string) =>
    `${membersCollection}/${memberId}/${bookingsSubCollection}`;

export const bookingDocument = (memberId: string, bookingId: string) =>
    doc(db, bookingsCollection(memberId), bookingId);

export const queryBookings = (memberId: string) =>
    query(
        collection(db, bookingsCollection(memberId)),
        orderBy("startTime", "desc"),
    );

export async function memberGetProposal(request: string) {
    return httpsCallable<MemberGetProposalPayload, MemberGetProposalResponse>(
        functions,
        "memberGetProposal",
    )({ request });
}

export async function memberMarkConversationMessagesRead(
    payload: MarkConversationMessagesReadPayload,
) {
    return httpsCallable(
        functions,
        "memberMarkConversationMessagesRead",
    )(payload);
}

// there might be issue also here
export async function updateMemberPreferences(
    data: PreferencesPayload,
    member: Member,
) {
    return httpsCallable<
        { onboarding: PreferencesPayload["onboarding"]; member: Member },
        { success: boolean }
    >(
        functions,
        "memberSendOnboardingPreferences",
    )({
        onboarding: data.onboarding,
        member,
    });
}

export async function finishOnboardingFlow(member: Member) {
    return httpsCallable<{ events: any; member: Member }, { success: boolean }>(
        functions,
        "memberSendUserEvents",
    )({
        events: [{ name: "onboarding_shown", source: "onboarding flow" }],
        member,
    });
}
