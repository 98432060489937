import React, { useEffect, useState } from "react";
import { Lib, useAlbertineTranslation } from "albertine-shared-web";
import "./OnboardingCommonStyles.css";
import { useOutletContext } from "react-router-dom";
import Transition from "./OnboardingTransition";
import { UpcomingDiningPlansAnswer } from "../../../../lmt/src/common/types/OnboardingPreferences";
import { OnboardingOutletContextType } from "../../types/OnboardingOutlet";
import { logError } from "../../error";

function OnboardDiningPlans() {
    const t = useAlbertineTranslation();
    const [step, setStep] = useState<number>(0);
    const {
        member,
        updateMemberPreferences,
        setProgress,
        finishOnboardingFlow,
    } = useOutletContext<OnboardingOutletContextType>();

    useEffect(() => {
        setProgress(100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sendDiningPlans = async (
        answer: UpcomingDiningPlansAnswer,
        query?: string,
    ): Promise<boolean> => {
        const preferences = query
            ? {
                  onboarding: {
                      anyUpcomingDiningPlans: answer,
                      upcomingDiningQuery: query,
                  },
              }
            : { onboarding: { anyUpcomingDiningPlans: answer } };

        try {
            await updateMemberPreferences(preferences, member!);
            if (query) {
                setStep(2);
            } else {
                finishOnboardingFlow(member!);
            }
            return true;
        } catch (error) {
            logError("updateMemberPreferences", error);
            return false;
        }
    };

    useEffect(() => {
        if (step === 2) {
            setTimeout(() => {
                finishOnboardingFlow(member!);
            }, 2000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);

    return (
        <Lib.Flex.Column>
            {step === 1 && (
                <Lib.Flex.Row>
                    <Lib.Button.Ghost
                        className="onboarding-back-button"
                        onClick={() => setStep(step - 1)}
                    >
                        <Lib.Icon.ArrowBack.Medium />
                    </Lib.Button.Ghost>

                    <Lib.Button.Ghost
                        className="onboarding-skip-button"
                        onClick={() =>
                            sendDiningPlans("yes", "user skipped the query")
                        }
                    >
                        <Lib.Paragraph.Small>
                            {t("onboarding__dining-plans__button__skip")}
                        </Lib.Paragraph.Small>
                    </Lib.Button.Ghost>
                </Lib.Flex.Row>
            )}

            <Lib.Flex.Column>
                {step === 0 && (
                    <Transition>
                        <Lib.Flex.Column gap="value24">
                            <Lib.Flex.Column gap="value8">
                                <Lib.Tag.Primary>
                                    {t("onboarding__dining-plans__tag")}
                                </Lib.Tag.Primary>

                                <Lib.Paragraph.XXLarge.Bold>
                                    {t("onboarding__dining-plans__intro-title")}
                                </Lib.Paragraph.XXLarge.Bold>

                                <Lib.Paragraph.Small.Reqular>
                                    {t("onboarding__dining-plans__intro-text")}
                                </Lib.Paragraph.Small.Reqular>
                            </Lib.Flex.Column>
                            <Lib.Flex.Column gap="value8">
                                <Lib.Button.Onboarding
                                    onClick={() => setStep(step + 1)}
                                >
                                    {t(
                                        "onboarding__dining-plans__button__first-answer",
                                    )}
                                </Lib.Button.Onboarding>

                                <Lib.Button.Onboarding
                                    onClick={() => sendDiningPlans("no")}
                                >
                                    {t(
                                        "onboarding__dining-plans__button__second-answer",
                                    )}
                                </Lib.Button.Onboarding>
                            </Lib.Flex.Column>
                        </Lib.Flex.Column>
                    </Transition>
                )}

                {step === 1 && (
                    <Transition>
                        <Lib.Flex.Column gap="value24">
                            <Lib.Flex.Column gap="value8">
                                <Lib.Tag.Primary>
                                    {t("onboarding__dining-plans__tag")}
                                </Lib.Tag.Primary>

                                <Lib.Paragraph.XXLarge.Bold>
                                    {t(
                                        "onboarding__dining-plans__details-title",
                                    )}
                                </Lib.Paragraph.XXLarge.Bold>

                                <Lib.Paragraph.Small.Reqular>
                                    {t("onboarding__dining-plans__intro-text")}
                                </Lib.Paragraph.Small.Reqular>
                            </Lib.Flex.Column>
                            <Lib.Flex.Column>
                                <Lib.TextAreaInput
                                    placeholder={t(
                                        "onboarding__dining-plans__input__placeholder",
                                    )}
                                    onSend={(query: string) =>
                                        sendDiningPlans("yes", query)
                                    }
                                    isActive
                                />
                            </Lib.Flex.Column>
                        </Lib.Flex.Column>
                    </Transition>
                )}

                {step === 2 && (
                    <Lib.Flex.Column>
                        <Transition>
                            <Lib.Paragraph.XXLarge.XBold.TextColor01 className="onboarding-transition-message">
                                {t(
                                    "onboarding__upcoming-plans__thanks-message",
                                )}
                            </Lib.Paragraph.XXLarge.XBold.TextColor01>
                        </Transition>
                    </Lib.Flex.Column>
                )}
            </Lib.Flex.Column>
        </Lib.Flex.Column>
    );
}

export default OnboardDiningPlans;
