import React, { useEffect, useState } from "react";
import {
    Lib,
    useAlbertineTranslation,
    useTenant,
    useUILanguage,
} from "albertine-shared-web";
import { useOpenSearch } from "../context/opensearch";
import ScreenHeader from "../components/ScreenHeader";
import { getArticle } from "../../../lmt/src/common/utils/opensearch/themes";
import {
    findTranslations,
    OpenSearchCatalogHitSource,
} from "../../../lmt/src/common/types/OpenSearch.CatalogItem";
import Header, { BackButton } from "../components/Header";
import Screen from "./Screen";
import "./ArticleDetails.css";
import ArticleTagTable from "../components/ArticleTagTable";
import ArticleMap from "../components/ArticleMap";
import { Tag } from "../../../lmt/src/common/tags";
import { useScreenStack } from "../context/screenStack";

type Props = {
    articleId: string | undefined;
};

function ArticleDetails(props: Props) {
    const { articleId } = props;
    const { opensearch } = useOpenSearch();
    const t = useAlbertineTranslation();
    const currentLanguage = useUILanguage();
    const { tenant } = useTenant();
    const { openChat } = useScreenStack();
    const [article, setArticle] = useState<
        OpenSearchCatalogHitSource | undefined
    >();
    useEffect(() => {
        async function fetchArticle() {
            if (opensearch && articleId) {
                const themeArticle = await getArticle(opensearch, articleId);
                setArticle(themeArticle);
            }
        }
        fetchArticle();
    }, [articleId, opensearch]);

    if (!article) {
        return null;
    }

    const translations = findTranslations(article, currentLanguage);

    return (
        <Screen.DiscoverBg04 className="article-details__screen-bg">
            <Header title={translations.name || ""} type="article" />
            <Lib.Background.DiscoverBg04 className="article-details__screen-bg">
                <Lib.Flex.Column>
                    <Lib.Background.DiscoverBg01
                        className="article-details__bg"
                        textColorInversable={tenant === "hugoboss"}
                    >
                        <BackButton />

                        <Lib.ContentCenter.Large>
                            <ScreenHeader
                                secondaryTitle={
                                    translations.keyDetail || undefined
                                }
                                primaryTitle={translations.name || ""}
                                className="article-details__header"
                            />
                        </Lib.ContentCenter.Large>
                    </Lib.Background.DiscoverBg01>
                    <Lib.ContentCenter.Large className="article-details">
                        <Lib.ImageCollection images={article.images || []} />

                        {translations.headline && (
                            <Lib.Paragraph.XLarge.MediumBold className="article-details__title">
                                {translations.headline}
                            </Lib.Paragraph.XLarge.MediumBold>
                        )}
                        {translations.description && (
                            <Lib.Paragraph.Medium.Reqular className="article-details__content-body article-details__description">
                                {translations.description}
                            </Lib.Paragraph.Medium.Reqular>
                        )}
                        {translations.benefits && (
                            <>
                                <Lib.Label.Medium.Bold
                                    transform="uppercase"
                                    className="article-details__benefit-title article-details__content-body"
                                >
                                    {t("article_albertine_benefit")}
                                </Lib.Label.Medium.Bold>
                                <Lib.Paragraph.Medium.Reqular className="article-details__benefit-text article-details__content-body">
                                    {translations.benefits}
                                </Lib.Paragraph.Medium.Reqular>
                            </>
                        )}
                    </Lib.ContentCenter.Large>

                    {translations.localInsights && (
                        <Lib.Background.DiscoverBg02>
                            <Lib.ContentCenter.Large className="article-details__insight-wrapper">
                                <Lib.Flex.Column>
                                    <Lib.Tag.Primary className="article_details__insight-tag">
                                        {t("article_albertine_insights")}
                                    </Lib.Tag.Primary>
                                    <Lib.Paragraph.Large.MediumBold className="article-details__insight-text">
                                        {translations.localInsights}
                                    </Lib.Paragraph.Large.MediumBold>
                                </Lib.Flex.Column>
                            </Lib.ContentCenter.Large>
                        </Lib.Background.DiscoverBg02>
                    )}

                    <Lib.Background.DiscoverBg03 className="article-details__map-wrapper">
                        {article.location &&
                            Object.keys(article.location).length !== 0 && (
                                <ArticleMap
                                    name={translations.name || ""}
                                    address={translations.address || null}
                                    website={article.website || null}
                                    location={article.location}
                                    image={article?.images?.[0] || null}
                                    placeId={article.googlePlaceId || null}
                                />
                            )}

                        {article?.tags?.length !== 0 && (
                            <Lib.ContentCenter.Large className="article-details__tags-table">
                                <Lib.Flex.Column>
                                    <ArticleTagTable
                                        tags={article.tags as Tag[]}
                                    />
                                </Lib.Flex.Column>
                            </Lib.ContentCenter.Large>
                        )}
                    </Lib.Background.DiscoverBg03>
                    <Lib.Background.DiscoverBg01
                        textColorInversable={tenant === "hugoboss"}
                    >
                        <Lib.ContentCenter.Medium className="article_details__footer">
                            <Lib.Flex.Column gap="value24" alignItems="center">
                                <Lib.Paragraph.Medium.Reqular className="article_details__footer-text">
                                    {t("article_footer_text")}
                                </Lib.Paragraph.Medium.Reqular>
                                <Lib.Line.Vertical />
                                <Lib.Button.Primary
                                    className="article_details__footer-button"
                                    onClick={() => openChat("all")}
                                >
                                    {t("article_footer_button_text")}
                                </Lib.Button.Primary>
                            </Lib.Flex.Column>
                        </Lib.ContentCenter.Medium>
                    </Lib.Background.DiscoverBg01>
                </Lib.Flex.Column>
            </Lib.Background.DiscoverBg04>
        </Screen.DiscoverBg04>
    );
}

export default ArticleDetails;
