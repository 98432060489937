import { getMessaging, getToken, isSupported } from "firebase/messaging";
import { z } from "zod";
import config from "../config";
import { updateMemberData } from "./firestore";
import { getDeviceId } from "../utils/localStorage.util";
import { logError } from "../error";

const NotificationResult = z.enum(["granted", "denied", "default"]);
export type NotificationResult = z.infer<typeof NotificationResult>;

// Service worker should be usually ready instantly but for localhost for example there is no service worker
function waitForServiceWorker(): Promise<
    ServiceWorkerRegistration | undefined
> {
    return new Promise((resolve) => {
        const timeout = setTimeout(() => {
            resolve(undefined);
        }, 1500);

        navigator.serviceWorker.ready
            .then((registration) => {
                clearTimeout(timeout);
                resolve(registration);
            })
            .catch((error) => {
                logError("waitForServiceWorker", error);
                clearTimeout(timeout);
                resolve(undefined);
            });
    });
}

export async function setupDeviceForNotifications() {
    try {
        const serviceWorkerRegistration = await waitForServiceWorker();

        if (serviceWorkerRegistration) {
            const messaging = getMessaging();

            const pushNotificationsToken = await getToken(messaging, {
                vapidKey: config.webPushKey,
                serviceWorkerRegistration,
            });

            await updateMemberData({
                deviceId: getDeviceId(),
                pushNotificationsEnabledWeb: true,
                pushNotificationsToken,
                webUserAgent: navigator.userAgent,
            });
        } else {
            await updateMemberData({
                deviceId: getDeviceId(),
                pushNotificationsEnabledWeb: false,
                webUserAgent: navigator.userAgent,
            });
        }
    } catch (error) {
        logError("setupDeviceForNotifications", error);
    }
}

export function getCurrentNotificationPermission(): NotificationResult {
    if (typeof Notification !== undefined) {
        return Notification.permission;
    }

    return "denied";
}

export function shouldAskNotificationPermission() {
    const currentPermission = getCurrentNotificationPermission();
    return currentPermission === "default";
}

export async function requestNotificationPermission(): Promise<NotificationResult> {
    const permission: NotificationResult =
        await Notification.requestPermission();

    if (permission === "granted") {
        const supported = await isSupported();
        if (supported) {
            await setupDeviceForNotifications();
        }
    } else {
        await updateMemberData({
            deviceId: getDeviceId(),
            pushNotificationsEnabledWeb: false,
            webUserAgent: navigator.userAgent,
        });
    }

    return permission;
}
