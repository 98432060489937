import {
    DocumentData,
    DocumentReference,
    getDoc,
    getDocs,
    Query,
} from "firebase/firestore";
import { z } from "zod";
import { logError } from "../error";

type SetState<T> = React.Dispatch<React.SetStateAction<T>>;

export async function loadDocument<T>(
    document: DocumentReference<DocumentData>,
    schema: z.ZodSchema<T>,
): Promise<T> {
    const doc = await getDoc(document);
    if (!doc.exists()) {
        throw new Error("Document does not exist");
    }

    const parsedDoc = schema.safeParse({
        ...doc.data(),
        id: doc.id,
    });

    if (!parsedDoc.success) {
        throw parsedDoc.error;
    }

    return parsedDoc.data;
}

export async function loadToDocumentToState<T>(
    document: DocumentReference<DocumentData>,
    schema: z.ZodSchema<T>,
    setState: SetState<T | undefined>,
) {
    try {
        const doc = await loadDocument<T>(document, schema);
        setState(doc);
    } catch (error) {
        logError("loadToDocumentToState", error);
        setState(undefined);
    }
}

export async function loadDocuments<T>(
    query: Query<DocumentData, DocumentData>,
    schema: z.ZodSchema<T>,
): Promise<T[]> {
    const docs = await getDocs(query);

    const parsedDocs = docs.docs
        .map((doc) => {
            const parsedDoc = schema.safeParse({
                ...doc.data(),
                id: doc.id,
            });

            if (!parsedDoc.success) {
                logError("loadDocuments", parsedDoc.error);
                return undefined;
            }

            return parsedDoc.data;
        })
        .filter((doc): doc is T => doc !== undefined);

    return parsedDocs;
}

export async function loadToDocumentsToState<T>(
    query: Query<DocumentData, DocumentData>,
    schema: z.ZodSchema<T>,
    setState: SetState<T[] | undefined>,
) {
    try {
        const docs = await loadDocuments<T>(query, schema);
        setState(docs);
    } catch (error) {
        logError("loadToDocumentsToState", error);
        setState(undefined);
    }
}
