import { ServiceLanguage, translation } from "albertine-shared-web";
import * as z from "zod";

export const ValidateTag = z
    .string()
    .regex(/^[a-zA-Z0-9][a-zA-Z0-9-]+:[a-zA-Z0-9$-]+$/, "Invalid tag.");

export type ValidateTag = z.infer<typeof ValidateTag>;

export const TagCategory = z.enum([
    "activities",
    "cuisine",
    "goodFor",
    "meals",
    "price",
    "type",
    "vibe",
]);
export type TagCategory = z.infer<typeof TagCategory>;

export const Tag = z.enum(
    [
        "price:free",
        "price:inexpensive",
        "price:moderate",
        "price:expensive",
        "price:very-expensive",

        "type:restaurant",
        "type:cafe",
        "type:pop-up",
        "type:bar",
        "type:pub",
        "type:wine-bar",
        "type:cocktail-bar",
        "type:rooftop",
        "type:nightclub",
        "type:live-music",
        "type:commercial-airline",
        "type:private-airline",
        "type:hotel",
        "type:villa",
        "type:apartment",
        "type:house",
        "type:resort",
        "type:yacht",
        "type:bed-and-breakfast",
        "type:car-rental",
        "type:car-transfer",
        "type:boat-rental",
        "type:charter-boat",
        "type:helicopter",
        "type:water-taxi",
        "type:sports",
        "type:theatre",
        "type:music",
        "type:fashion",
        "type:art",
        "type:dance",
        "type:museum",
        "type:art-gallery",
        "type:historical",
        "type:tourist-attraction",
        "type:must-do",
        "type:hidden-gem",
        "type:outdoors",
        "type:bookshop",
        "type:watches",
        "type:corporate-gifts",
        "type:personal-gifts",
        "type:collectibles",
        "type:spa",
        "type:sauna",
        "type:yoga",
        "type:ayurveda",
        "type:reiki",
        "type:massage",
        "type:beauty-salon",
        "type:personal-trainer",
        "type:retreat",
        "type:surfing",
        "type:wellness-hotel",
        "type:workout-studio",
        "type:flower-delivery",
        "type:cleaning-service",
        "type:moving-service",
        "type:real-estate",
        "type:education",
        "type:childcare",
        "type:bakery",
        "type:food-court",

        "vibe:boutique",
        "vibe:casual",
        "vibe:casual",
        "vibe:elegant",
        "vibe:high-end",
        "vibe:local-and-authentic",
        "vibe:luxury",
        "vibe:modern",
        "vibe:quirky",
        "vibe:romantic",
        "vibe:touristy",
        "vibe:traditional",
        "vibe:trendy",
        "vibe:fine-dining",
        "vibe:intimate",

        "goodFor:business",
        "goodFor:couples",
        "goodFor:dates",
        "goodFor:families",
        "goodFor:foodies",
        "goodFor:groups",
        "goodFor:locals",
        "goodFor:parties",
        "goodFor:private-dining",
        "goodFor:tourists",
        "goodFor:vegans",
        "goodFor:vegetarians",
        "goodFor:friends",
        "goodFor:friends-catchup",
        "goodFor:solo-trip",

        "meals:breakfast",
        "meals:brunch",
        "meals:lunch",
        "meals:dinner",
        "meals:quick-bites",

        "cuisine:african",
        "cuisine:american",
        "cuisine:asian",
        "cuisine:british",
        "cuisine:chinese",
        "cuisine:french",
        "cuisine:greek",
        "cuisine:indian",
        "cuisine:italian",
        "cuisine:japanese",
        "cuisine:latin",
        "cuisine:mediterranean",
        "cuisine:mexican",
        "cuisine:middle-eastern",
        "cuisine:scandinavian",
        "cuisine:seafood",
        "cuisine:spanish",
        "cuisine:steakhouse",

        "activities:adventurous",
        "activities:beach",
        "activities:city-break",
        "activities:cultural",
        "activities:hiking",
        "activities:nature",
        "activities:sea",
        "activities:safari",
        "activities:skiing",
    ],
    { errorMap: () => ({ message: "Invalid tag." }) },
);
export type Tag = z.infer<typeof Tag>;

export type TagMap = { [key: string]: Tag };
export type AllTags = { [key in TagCategory]: TagMap };
export type CategorySpecificTags = { [key in TagCategory]?: TagMap };

export const Tags: AllTags = {
    price: {
        veryExpensive: Tag.Enum["price:very-expensive"],
        expensive: Tag.Enum["price:expensive"],
        moderate: Tag.Enum["price:moderate"],
        inexpensive: Tag.Enum["price:inexpensive"],
        free: Tag.Enum["price:free"],
    },
    type: {
        restaurant: Tag.Enum["type:restaurant"],
        cafe: Tag.Enum["type:cafe"],
        popUp: Tag.Enum["type:pop-up"],

        bar: Tag.Enum["type:bar"],
        pub: Tag.Enum["type:pub"],
        wineBar: Tag.Enum["type:wine-bar"],
        cocktailBar: Tag.Enum["type:cocktail-bar"],
        rooftop: Tag.Enum["type:rooftop"],
        nightclub: Tag.Enum["type:nightclub"],
        liveMusic: Tag.Enum["type:live-music"],
        commercialAirline: Tag.Enum["type:commercial-airline"],
        privateAirline: Tag.Enum["type:private-airline"],
        hotel: Tag.Enum["type:hotel"],
        villa: Tag.Enum["type:villa"],
        apartment: Tag.Enum["type:apartment"],
        house: Tag.Enum["type:house"],
        resort: Tag.Enum["type:resort"],
        yacht: Tag.Enum["type:yacht"],
        bedAndBreakfast: Tag.Enum["type:bed-and-breakfast"],
        carRental: Tag.Enum["type:car-rental"],
        carTransfer: Tag.Enum["type:car-transfer"],
        boatRental: Tag.Enum["type:boat-rental"],
        charterBoat: Tag.Enum["type:charter-boat"],
        helicopter: Tag.Enum["type:helicopter"],
        waterTaxi: Tag.Enum["type:water-taxi"],
        sports: Tag.Enum["type:sports"],
        theatre: Tag.Enum["type:theatre"],
        music: Tag.Enum["type:music"],
        fashion: Tag.Enum["type:fashion"],
        art: Tag.Enum["type:art"],
        dance: Tag.Enum["type:dance"],
        museum: Tag.Enum["type:museum"],
        artGallery: Tag.Enum["type:art-gallery"],
        historical: Tag.Enum["type:historical"],
        touristAttraction: Tag.Enum["type:tourist-attraction"],
        mustDo: "type:must-do",
        hiddenGem: "type:hidden-gem",
        outdoors: Tag.Enum["type:outdoors"],
        bookshop: Tag.Enum["type:bookshop"],
        watches: Tag.Enum["type:watches"],
        corporateGifts: Tag.Enum["type:corporate-gifts"],
        personalGifts: Tag.Enum["type:personal-gifts"],
        collectibles: Tag.Enum["type:collectibles"],
        spa: Tag.Enum["type:spa"],
        sauna: Tag.Enum["type:sauna"],
        yoga: Tag.Enum["type:yoga"],
        ayurveda: Tag.Enum["type:ayurveda"],
        reiki: Tag.Enum["type:reiki"],
        massage: Tag.Enum["type:massage"],
        beautySalon: Tag.Enum["type:beauty-salon"],
        personalTrainer: Tag.Enum["type:personal-trainer"],
        retreat: Tag.Enum["type:retreat"],
        surfing: Tag.Enum["type:surfing"],
        wellnessHotel: Tag.Enum["type:wellness-hotel"],
        workoutStudio: Tag.Enum["type:workout-studio"],
        flowerDelivery: Tag.Enum["type:flower-delivery"],
        cleaningService: Tag.Enum["type:cleaning-service"],
        movingService: Tag.Enum["type:moving-service"],
        realEstate: Tag.Enum["type:real-estate"],
        education: Tag.Enum["type:education"],
        childcare: Tag.Enum["type:childcare"],
        bakery: Tag.Enum["type:bakery"],
        foodCourt: Tag.Enum["type:food-court"],
    },
    meals: {
        breakfast: Tag.Enum["meals:breakfast"],
        brunch: Tag.Enum["meals:brunch"],
        dinner: Tag.Enum["meals:dinner"],
        lunch: Tag.Enum["meals:lunch"],
        quickBites: Tag.Enum["meals:quick-bites"],
    },
    vibe: {
        boutique: Tag.Enum["vibe:boutique"],
        casual: Tag.Enum["vibe:casual"],
        elegant: Tag.Enum["vibe:elegant"],
        fineDining: Tag.Enum["vibe:fine-dining"],
        highEnd: Tag.Enum["vibe:high-end"],
        intimate: Tag.Enum["vibe:intimate"],
        localAndAuthentic: Tag.Enum["vibe:local-and-authentic"],
        luxury: Tag.Enum["vibe:luxury"],
        modern: Tag.Enum["vibe:modern"],
        quirky: Tag.Enum["vibe:quirky"],
        romantic: Tag.Enum["vibe:romantic"],
        touristy: Tag.Enum["vibe:touristy"],
        traditional: Tag.Enum["vibe:traditional"],
        trendy: Tag.Enum["vibe:trendy"],
    },
    goodFor: {
        business: Tag.Enum["goodFor:business"],
        couples: Tag.Enum["goodFor:couples"],
        dates: Tag.Enum["goodFor:dates"],
        families: Tag.Enum["goodFor:families"],
        foodies: Tag.Enum["goodFor:foodies"],
        friends: Tag.Enum["goodFor:friends"],
        friendsCatchup: Tag.Enum["goodFor:friends-catchup"],
        groups: Tag.Enum["goodFor:groups"],
        locals: Tag.Enum["goodFor:locals"],
        parties: Tag.Enum["goodFor:parties"],
        privateDining: Tag.Enum["goodFor:private-dining"],
        soloTrip: Tag.Enum["goodFor:solo-trip"],
        tourists: Tag.Enum["goodFor:tourists"],
        vegans: Tag.Enum["goodFor:vegans"],
        vegetarians: Tag.Enum["goodFor:vegetarians"],
    },
    cuisine: {
        african: Tag.Enum["cuisine:african"],
        american: Tag.Enum["cuisine:american"],
        asian: Tag.Enum["cuisine:asian"],
        british: Tag.Enum["cuisine:british"],
        chinese: Tag.Enum["cuisine:chinese"],
        french: Tag.Enum["cuisine:french"],
        greek: Tag.Enum["cuisine:greek"],
        indian: Tag.Enum["cuisine:indian"],
        italian: Tag.Enum["cuisine:italian"],
        japanese: Tag.Enum["cuisine:japanese"],
        latin: Tag.Enum["cuisine:latin"],
        mediterranean: Tag.Enum["cuisine:mediterranean"],
        mexican: Tag.Enum["cuisine:mexican"],
        middleEastern: Tag.Enum["cuisine:middle-eastern"],
        scandinavian: Tag.Enum["cuisine:scandinavian"],
        seafood: Tag.Enum["cuisine:seafood"],
        spanish: Tag.Enum["cuisine:spanish"],
        steakhouse: Tag.Enum["cuisine:steakhouse"],
    },
    activities: {
        adventurous: Tag.Enum["activities:adventurous"],
        beach: Tag.Enum["activities:beach"],
        cityBreak: Tag.Enum["activities:city-break"],
        cultural: Tag.Enum["activities:cultural"],
        hiking: Tag.Enum["activities:hiking"],
        nature: Tag.Enum["activities:nature"],
        sea: Tag.Enum["activities:sea"],
        safari: Tag.Enum["activities:safari"],
        skiing: Tag.Enum["activities:skiing"],
    },
};

export const TagCategoryToDisplayName: { [key in TagCategory]: string } = {
    activities: "Activities",
    cuisine: "Cuisine",
    goodFor: "Good for",
    meals: "Meals",
    price: "Price range",
    type: "Type",
    vibe: "Vibe",
};

export function tagDisplayName(
    tag: Tag,
    serviceLanguage: ServiceLanguage,
): string {
    return translation(`tags.${tag}`, {
        lng: serviceLanguage,
    });
}

// deconstruct tags to allow to create category specific tag lists
const {
    price: { inexpensive, moderate, expensive, veryExpensive },
    type: {
        restaurant,
        cafe,
        popUp,
        bar,
        pub,
        wineBar,
        cocktailBar,
        rooftop,
        nightclub,
        liveMusic,
        commercialAirline,
        privateAirline,
        hotel,
        villa,
        apartment,
        house,
        resort,
        yacht,
        bedAndBreakfast,
        carRental,
        carTransfer,
        boatRental,
        charterBoat,
        helicopter,
        waterTaxi,
        sports,
        theatre,
        music,
        fashion,
        art,
        dance,
        museum,
        artGallery,
        historical,
        touristAttraction,
        mustDo,
        hiddenGem,
        outdoors,
        bookshop,
        watches,
        corporateGifts,
        personalGifts,
        collectibles,
        spa,
        sauna,
        yoga,
        ayurveda,
        reiki,
        massage,
        beautySalon,
        personalTrainer,
        retreat,
        surfing,
        wellnessHotel,
        workoutStudio,
        flowerDelivery,
        cleaningService,
        movingService,
        realEstate,
        education,
        childcare,
        bakery,
        foodCourt,
    },
    goodFor: {
        business,
        couples,
        dates,
        families,
        foodies,
        locals,
        groups,
        parties,
        privateDining,
        tourists,
        vegetarians,
        vegans,
        friends,
        friendsCatchup,
        soloTrip,
    },
    vibe: {
        boutique,
        casual,
        elegant,
        highEnd,
        localAndAuthentic,
        luxury,
        modern,
        quirky,
        romantic,
        touristy,
        traditional,
        trendy,
        fineDining,
        intimate,
    },
} = Tags;

const pricesExcludingFree = {
    veryExpensive,
    expensive,
    moderate,
    inexpensive,
};

export const diningTags: CategorySpecificTags = {
    type: {
        bakery,
        bar,
        cafe,
        foodCourt,
        popUp,
        restaurant,
    },
    goodFor: {
        business,
        dates,
        families,
        friendsCatchup,
        groups,
        privateDining,
        vegans,
        vegetarians,
    },
    vibe: {
        casual,
        elegant,
        fineDining,
        highEnd,
        intimate,
        localAndAuthentic,
        modern,
        quirky,
        romantic,
        traditional,
        trendy,
    },
    meals: { ...Tags.meals },
    cuisine: { ...Tags.cuisine },
    price: { ...pricesExcludingFree },
};

export const nightlifeTags: CategorySpecificTags = {
    type: {
        bar,
        pub,
        wineBar,
        cocktailBar,
        rooftop,
        nightclub,
        liveMusic,
    },
    goodFor: {
        business,
        dates,
        friendsCatchup,
        groups,
        parties,
    },
    vibe: {
        casual,
        elegant,
        highEnd,
        localAndAuthentic,
        modern,
        quirky,
        romantic,
        traditional,
        trendy,
    },
    price: { ...Tags.price },
};

export const flightTags: CategorySpecificTags = {
    type: {
        commercialAirline,
        privateAirline,
    },
};

export const accommodationTags: CategorySpecificTags = {
    type: {
        hotel,
        villa,
        apartment,
        house,
        resort,
        yacht,
        bedAndBreakfast,
    },
    goodFor: {
        business,
        couples,
        families,
        friends,
        groups,
        soloTrip,
    },
    vibe: {
        boutique,
        casual,
        elegant,
        localAndAuthentic,
        luxury,
        modern,
        quirky,
        romantic,
        trendy,
        traditional,
    },
    price: { ...Tags.price },
};

export const travelItineraryTags: CategorySpecificTags = {
    goodFor: {
        business,
        couples,
        families,
        friends,
        groups,
        soloTrip,
    },
    activities: { ...Tags.activities },
    price: { ...Tags.price },
};

export const transportationTags: CategorySpecificTags = {
    type: {
        carRental,
        carTransfer,
        boatRental,
        charterBoat,
        helicopter,
        waterTaxi,
    },
    price: { ...Tags.price },
};

export const eventTags: CategorySpecificTags = {
    type: {
        sports,
        theatre,
        music,
        fashion,
        art,
        dance,
    },
    price: { ...Tags.price },
};

export const localExperienceTags: CategorySpecificTags = {
    type: {
        museum,
        artGallery,
        historical,
        touristAttraction,
        mustDo,
        hiddenGem,
        outdoors,
        bookshop,
    },
    goodFor: {
        business,
        couples,
        families,
        foodies,
        friends,
        groups,
        locals,
        tourists,
    },
    vibe: {
        highEnd,
        localAndAuthentic,
        touristy,
    },
    price: { ...Tags.price },
};

export const shoppingTags: CategorySpecificTags = {
    type: {
        fashion,
        watches,
        corporateGifts,
        personalGifts,
        collectibles,
    },
    price: { ...Tags.price },
};

export const wellnessTags: CategorySpecificTags = {
    type: {
        spa,
        sauna,
        yoga,
        ayurveda,
        reiki,
        massage,
        beautySalon,
        personalTrainer,
        retreat,
        surfing,
        wellnessHotel,
        workoutStudio,
    },
    price: { ...Tags.price },
};

export const servicesTags: CategorySpecificTags = {
    type: {
        flowerDelivery,
        cleaningService,
        movingService,
        realEstate,
        education,
        childcare,
    },
    price: { ...Tags.price },
};
