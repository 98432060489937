import React, { useMemo } from "react";
import "./CountryCodes.css";
import { Lib, useUILanguage } from "albertine-shared-web";
import classNames from "classnames";
import { countryCodes, gbCountryCode } from "../constants/countryCodes";
import { PhoneNumberCountryCode } from "../types/PhoneNumber";

type CountryCodesProps = {
    phoneNumber: PhoneNumberCountryCode;
    setPhoneNumber: (value: PhoneNumberCountryCode) => void;
    className?: string;
};

function formatOptionValue(
    value: Pick<(typeof countryCodes)[0], "dialCode" | "code">,
): string {
    return `${value.dialCode}|${value.code}`;
}

function parseOptionValue(value: string): PhoneNumberCountryCode {
    const [dialCode, countryCode] = value.split("|");
    return { dialCode, countryCode };
}

export default function CountryCodes({
    phoneNumber,
    setPhoneNumber,
    className,
}: CountryCodesProps) {
    const countryCodesClass = classNames("country_codes ", className);
    const lang = useUILanguage();

    const options = useMemo(() => {
        /* TODO:
            Once the country code selector supports longer inputs and proper formatting
            sort the countries alphabetically by their localized name, this makes sure
            the order is consistent from the users perspective.
            Sort the country codes by localized name, makes sure the order is consistent for users.

        const localizedCountryName = new Intl.DisplayNames(lang, {
            type: "region",
        });

        // Localize the country name based on the current language, but fallback to the built-in name.
        const nameLocalized = countryCodes.map((countryCode) => ({
            ...countryCode,
            name: localizedCountryName.of(countryCode.code) ?? countryCode.name,
        }));
         const sortedCountryCodes = nameLocalized.sort((a, b) =>
             a.name.localeCompare(b.name),
         ); */
        const uniqueDialCodes = new Set();
        const sortedCountryCodes = countryCodes
            .filter((countryCode) => {
                if (uniqueDialCodes.has(countryCode.dialCode)) {
                    return false;
                }

                // Make sure that our default GB is in the unique list
                if (
                    countryCode.dialCode === gbCountryCode.dialCode &&
                    countryCode.code !== gbCountryCode.code
                ) {
                    return false;
                }

                uniqueDialCodes.add(countryCode.dialCode);
                return true;
            })
            .sort((a, b) =>
                a.dialCode.localeCompare(b.dialCode, undefined, {
                    numeric: true,
                }),
            );

        return sortedCountryCodes.map((countryCode) => (
            <option
                key={countryCode.code}
                value={formatOptionValue(countryCode)}
            >
                {countryCode.dialCode}
            </option>
        ));
    }, [lang]);

    return (
        <div className="country_codes__wrapper">
            <select
                className={countryCodesClass}
                value={formatOptionValue({
                    code: phoneNumber.countryCode ?? "",
                    dialCode: phoneNumber.dialCode,
                })}
                onChange={(event) => {
                    const parsedValue = parseOptionValue(event.target.value);
                    setPhoneNumber(parsedValue);
                }}
            >
                {options}
            </select>

            <span className="country_codes__custom_arrow">
                <Lib.Icon.ChevronRight />
            </span>
        </div>
    );
}
