import { ServiceLanguage } from "albertine-shared-web";
import {
    Tag,
    TagCategoryToDisplayName,
    tagDisplayName,
} from "../../../lmt/src/common/tags";

export function categorizeTags(tags: Tag[]) {
    const categories: { [key: string]: string[] } = {};
    tags.forEach((tag) => {
        const [key, value] = tag.split(":");
        if (!categories[key]) {
            categories[key] = [];
        }
        categories[key].push(value);
    });
    return categories;
}

export function mapTagsToReadableArray(
    tags: { [key: string]: string[] },
    serviceLanguage: ServiceLanguage,
) {
    return Object.keys(tags).map((category) => {
        const title =
            TagCategoryToDisplayName[
                category as keyof typeof TagCategoryToDisplayName
            ];
        const values = tags[category]
            .map(
                (tag) =>
                    tagDisplayName(
                        `${category}:${tag}` as Tag,
                        serviceLanguage,
                    ), // TODO: Remove the need for casting to tag
            )
            .join(", ");
        return { title, values };
    });
}
