import { z } from "zod";
import { OnboardingSteps } from "../types/OnboardingSteps";
import { PhoneNumber } from "../types/PhoneNumber";
import { gbCountryCode } from "../constants/countryCodes";

const phoneNumberKey = "albertine-phone-number";
const loginEmailKey = "albertine-login-email";
const locationPermissionKey = "albertine-location-permission";
const deviceIdLocalStorageKey = "albertine-deviceId";
export const chatMessagesKey = "albertine-chat-messages";
const onboardingNextStep = "concierge-onboarding-next-step";

export function getLocalStorageData<T>(
    key: string,
    schema: z.ZodSchema<T>,
): T | undefined {
    const storedData = localStorage.getItem(key);
    if (!storedData) return undefined;
    const parsed = schema.safeParse(JSON.parse(storedData));
    if (!parsed.success) return undefined;
    return parsed.data;
}

export function setLoginPhoneNumber(phoneNumber: PhoneNumber) {
    localStorage.setItem(phoneNumberKey, JSON.stringify(phoneNumber));
}
export function setLoginEmail(email: string) {
    localStorage.setItem(loginEmailKey, email);
}

const LocationState = z.enum(["granted", "denied"]);
type LocationState = z.infer<typeof LocationState>;
const LocationPermission = z.object({
    state: LocationState,
    error: z.boolean(),
});
type LocationPermission = z.infer<typeof LocationPermission>;
export function setLocationPermission(permission: LocationPermission) {
    localStorage.setItem(locationPermissionKey, JSON.stringify(permission));
}
export function getLocationPermission(): LocationPermission | undefined {
    return getLocalStorageData(locationPermissionKey, LocationPermission);
}

const defaultPhoneNumber: PhoneNumber = {
    dialCode: gbCountryCode.dialCode,
    countryCode: gbCountryCode.code,
    phoneNumber: "",
    parsedPhoneNumber: gbCountryCode.dialCode,
    isValid: false,
};

export function getLoginPhoneNumber(): PhoneNumber {
    const item = localStorage.getItem(phoneNumberKey);

    if (item === null) {
        return defaultPhoneNumber;
    }

    try {
        const data = PhoneNumber.safeParse(JSON.parse(item));
        if (data.success) {
            return data.data;
        }

        return defaultPhoneNumber;
    } catch (error) {
        console.warn(
            "Error parsing phone number from localStorage",
            item,
            error,
        );
        return defaultPhoneNumber;
    }
}

export function getLoginEmail(): string | null {
    return localStorage.getItem(loginEmailKey);
}

export function getDeviceId(): string {
    const deviceId = localStorage.getItem(deviceIdLocalStorageKey);
    if (deviceId !== null) {
        return deviceId;
    }

    const generatedDeviceId = crypto.randomUUID();
    localStorage.setItem(deviceIdLocalStorageKey, generatedDeviceId);
    return generatedDeviceId;
}

export function getOnboardingNextStep(): OnboardingSteps | null {
    const step = localStorage.getItem(onboardingNextStep);
    const result = OnboardingSteps.safeParse(step);
    return result.success ? result.data : null;
}
export function setOnboardingNextStep(nextStep: OnboardingSteps) {
    localStorage.setItem(onboardingNextStep, nextStep);
}
export function removeOnboardingNextStep() {
    localStorage.removeItem(onboardingNextStep);
}
