import { Lib, useAlbertineTranslation, useTenant } from "albertine-shared-web";
import React from "react";
import Screen from "./Screen";
import { useAuth } from "../context/auth";
import ListenToBookings from "../loaders/ListenToBookings";
import { sortBookings } from "../utils/bookings.util";
import PastBookings from "../components/PastBookings";
import UpcomingBookings from "../components/UpcomingBookings";
import ScreenHeader from "../components/ScreenHeader";
import BookingEmptyState from "../components/BookingEmptyState";
import { useScreenStack } from "../context/screenStack";
import Loading from "../components/Loading";
import "./Bookings.css";
import ListenToMember from "../loaders/ListenToMember";

type BookingsProps = {
    currentMemberId: string;
};

function Bookings(props: BookingsProps) {
    const { currentMemberId } = props;
    const { user } = useAuth();
    const t = useAlbertineTranslation();
    const { tenant, dependencyInjection } = useTenant();
    const { openProfile } = useScreenStack();

    return user ? (
        <Screen.BookingBg02>
            <Lib.Background.BookingBg01
                textColorInversable={tenant === "hugoboss"}
            >
                <Lib.ContentCenter.Medium className="bookings__header-container">
                    <ListenToMember memberId={currentMemberId}>
                        {(member) => (
                            <ScreenHeader
                                secondaryTitle={t("bookings__secondary-title", {
                                    username:
                                        member.greetingsName ||
                                        member.firstName,
                                })}
                                primaryTitle={t(
                                    "bookings__primary-title-default",
                                )}
                            />
                        )}
                    </ListenToMember>
                </Lib.ContentCenter.Medium>
            </Lib.Background.BookingBg01>

            <ListenToBookings
                memberId={currentMemberId}
                isLoading={
                    <Lib.ContentCenter.Medium>
                        <Loading />
                    </Lib.ContentCenter.Medium>
                }
            >
                {(bookings) => {
                    const pastBookings = sortBookings("past", bookings);
                    const upcomingBookings = sortBookings("upcoming", bookings);
                    return (
                        <Lib.Flex.Column gap="value48" className="bookings">
                            {upcomingBookings.length !== 0 ? (
                                <Lib.ContentCenter.Medium className="bookings__upcoming-bookings">
                                    <UpcomingBookings
                                        bookings={upcomingBookings}
                                    />
                                </Lib.ContentCenter.Medium>
                            ) : (
                                <Lib.ContentCenter.Medium className="bookings__empty-state">
                                    <BookingEmptyState />
                                </Lib.ContentCenter.Medium>
                            )}

                            {pastBookings.length !== 0 && (
                                <Lib.Background.BookingBg03 className="bookings__past-bookings">
                                    <Lib.ContentCenter.Medium>
                                        <PastBookings bookings={pastBookings} />
                                    </Lib.ContentCenter.Medium>
                                </Lib.Background.BookingBg03>
                            )}
                        </Lib.Flex.Column>
                    );
                }}
            </ListenToBookings>

            {dependencyInjection?.bookings.ProfileButton({
                onClick: openProfile,
                className: "bookings__profile-button",
                userId: user.uid,
                userName: user.displayName || "",
            })}
        </Screen.BookingBg02>
    ) : null;
}

export default Bookings;
