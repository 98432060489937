import { useEffect } from "react";

type UseDocumentVisiblityProps = {
    onAppear?: () => void;
    onHide?: () => void;
};

const useDocumentVisibility = (props: UseDocumentVisiblityProps) => {
    const { onAppear, onHide } = props;

    useEffect(() => {
        const listener = () => {
            if (document.hidden) {
                if (onHide) {
                    onHide();
                }
            } else if (onAppear) {
                onAppear();
            }
        };

        document.addEventListener("visibilitychange", listener);

        return () => {
            document.removeEventListener("visibilitychange", listener);
        };
    }, []);
};

export default useDocumentVisibility;
