type RetryableFn<T> = () => Promise<T>;
type RetryOpts = {
    maxRetries?: number;
    minDelay?: number;
    maxDelay?: number;
    jitter?: number;
};

export default function retry<T>(
    fn: RetryableFn<T>,
    {
        maxRetries = 5,
        minDelay = 1000,
        maxDelay = 30_000,
        jitter = 0.1,
    }: RetryOpts,
) {
    return new Promise<T>((resolve, reject) => {
        let retries = 0;
        const tryFn = () => {
            fn()
                .then(resolve)
                .catch((error) => {
                    if (retries >= maxRetries) {
                        reject(error);
                        return;
                    }

                    const min =
                        minDelay * 2 ** retries * (1 + Math.random() * jitter);
                    const max = maxDelay;

                    const delay = Math.min(min, max);
                    retries += 1;

                    console.log("Retry failed, retrying again in %dms", delay, {
                        retries,
                        min,
                        max,
                    });
                    setTimeout(tryFn, delay);
                });
        };

        tryFn();
    });
}
