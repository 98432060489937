import React, { useRef, useState, useEffect, useCallback } from "react";
import { Lib, useAlbertineTranslation } from "albertine-shared-web";
import { useGoogleMapsScript } from "../hooks/useScript";
import "./TopCitySearchInputField.css";
import {
    OnboardingTopCityList,
    OnboardingTopCityListItem,
} from "../api/topCities";

interface TopCitySearchInputFieldProps {
    onSelect: (city: OnboardingTopCityListItem) => void;
    areAllCitiesSelected: boolean;
    selectedCities: OnboardingTopCityList;
    onInputChange: (isInputActive: boolean) => void;
}

function TopCitySearchInputField(props: TopCitySearchInputFieldProps) {
    const { onSelect, selectedCities, areAllCitiesSelected, onInputChange } =
        props;
    const t = useAlbertineTranslation();
    const inputRef = useRef<HTMLInputElement>(null);
    const scriptLoadStatus = useGoogleMapsScript();
    const [predictions, setPredictions] = useState<any[]>([]);

    const initializeAutocompleteService = useCallback(() => {
        if (window.google) {
            const autocompleteService =
                new window.google.maps.places.AutocompleteService();

            const handleInputChange = () => {
                const inputValue = inputRef.current?.value;
                if (inputValue) {
                    onInputChange(true);
                    autocompleteService.getPlacePredictions(
                        { input: inputValue, types: ["(cities)"] },
                        (cityPredictions, status) => {
                            if (
                                status ===
                                window.google.maps.places.PlacesServiceStatus.OK
                            ) {
                                setPredictions(cityPredictions || []);
                            } else {
                                setPredictions([]);
                            }
                        },
                    );
                } else {
                    setPredictions([]);
                    onInputChange(false);
                }
            };

            inputRef.current?.addEventListener("input", handleInputChange);
        }
    }, [inputRef, onInputChange, setPredictions]);

    const clearInput = () => {
        if (inputRef.current) {
            inputRef.current.value = "";
        }
        setPredictions([]);
        onInputChange(false);
    };

    const fromGooglePlaceToTopCity = (
        place: google.maps.places.PlaceResult,
    ): OnboardingTopCityListItem | null => {
        const city =
            place.address_components?.find((comp) =>
                comp.types.includes("locality"),
            )?.long_name || "";
        const country =
            place.address_components?.find((comp) =>
                comp.types.includes("country"),
            )?.long_name || "";
        const placeId = place.place_id;
        const photoUrl = place.photos?.[0]?.getUrl({ maxWidth: 296 }) || "";

        const isDuplicate = selectedCities.some(
            (selectedCity: OnboardingTopCityListItem) =>
                selectedCity.googlePlaceId === placeId,
        );

        if (!isDuplicate && !areAllCitiesSelected) {
            return {
                id: "",
                city,
                country,
                googlePlaceId: placeId || "",
                image: photoUrl || "",
            };
        }

        return null;
    };

    const handlePredictionClick = (prediction: any) => {
        if (window.google) {
            const placesService = new window.google.maps.places.PlacesService(
                document.createElement("div"),
            );
            placesService.getDetails(
                { placeId: prediction.place_id },
                (place, status) => {
                    if (
                        status ===
                            window.google.maps.places.PlacesServiceStatus.OK &&
                        place
                    ) {
                        const city = fromGooglePlaceToTopCity(place);
                        if (city) {
                            onSelect(city);
                        }
                    }
                },
            );
        }

        setPredictions([]);
        clearInput();
    };

    useEffect(() => {
        if (scriptLoadStatus === "ready" && window.google) {
            initializeAutocompleteService();
        }
    }, [initializeAutocompleteService, scriptLoadStatus]);

    return (
        <div className="top-city-search__input-container">
            <Lib.Flex.Column gap="value32">
                <Lib.Flex.Row>
                    <input
                        ref={inputRef}
                        placeholder="Search destinations"
                        className="top-city-search__input"
                    />
                    <span className="top-city-search__input-icon">
                        {inputRef.current?.value ? (
                            <Lib.Button.Ghost onClick={clearInput}>
                                <Lib.Icon.Cancel />
                            </Lib.Button.Ghost>
                        ) : (
                            <Lib.Icon.Search />
                        )}
                    </span>
                </Lib.Flex.Row>
                {predictions?.length === 0 && inputRef.current?.value && (
                    <div className="top-city-search__predictions">
                        <Lib.Flex.Row>
                            <Lib.Label.Medium.Reqular className="top-city-search__no-results">
                                {t("top-city-search__no-results")}
                            </Lib.Label.Medium.Reqular>
                        </Lib.Flex.Row>
                    </div>
                )}
                {predictions.length > 0 && inputRef.current?.value && (
                    <div className="top-city-search__predictions">
                        <Lib.Flex.Row
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Lib.Flex.Column gap="value12">
                                {predictions.map((prediction) => {
                                    const predictionCity =
                                        prediction.structured_formatting
                                            .main_text;
                                    const predictionCountry =
                                        prediction.structured_formatting
                                            .secondary_text;

                                    return (
                                        <Lib.Flex.Row key={prediction.place_id}>
                                            <Lib.Button.Ghost
                                                onClick={() =>
                                                    handlePredictionClick(
                                                        prediction,
                                                    )
                                                }
                                            >
                                                <Lib.Label.Large.Reqular>
                                                    {predictionCity}
                                                </Lib.Label.Large.Reqular>
                                                <Lib.Label.Medium.Reqular>
                                                    {predictionCountry}
                                                </Lib.Label.Medium.Reqular>
                                            </Lib.Button.Ghost>
                                        </Lib.Flex.Row>
                                    );
                                })}
                            </Lib.Flex.Column>
                        </Lib.Flex.Row>
                    </div>
                )}
            </Lib.Flex.Column>
        </div>
    );
}

export default TopCitySearchInputField;
