/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from "react";
import { Lib, useTenant, useAlbertineTranslation } from "albertine-shared-web";
import { useOutletContext } from "react-router-dom";
import "./OnboardingCommonStyles.css";
import "./OnboardingCities.css";
import classnames from "classnames";
import { requestLocationPermission } from "../../api/location";
import fetchTopCities, {
    OnboardingTopCityList,
    OnboardingTopCityListItem,
} from "../../api/topCities";
import { OnboardingOutletContextType } from "../../types/OnboardingOutlet";
import Transition from "./OnboardingTransition";
import TopCitySearchInputField from "../../components/TopCitySearchInputField";
import { logError } from "../../error";

const maxCitiesSelected = 3;
interface InitialScreenProps {
    goToNextStep: () => void;
}

function InitialScreen(props: InitialScreenProps) {
    const { goToNextStep } = props;
    const t = useAlbertineTranslation();
    const { dependencyInjection } = useTenant();
    return (
        <Transition>
            <Lib.Flex.Column gap="value24">
                <Lib.Flex.Column gap="value8">
                    <Lib.Tag.Primary>
                        {t("onboarding__cities__tag")}
                    </Lib.Tag.Primary>
                    <Lib.Paragraph.XXLarge.XBold>
                        {t("onboarding__cities__title")}
                    </Lib.Paragraph.XXLarge.XBold>
                    <Lib.Paragraph.Small.Reqular>
                        {t("onboarding__cities__text")}
                    </Lib.Paragraph.Small.Reqular>
                </Lib.Flex.Column>
                <Lib.Flex.Column>
                    <Lib.Button.Onboarding type="button" onClick={goToNextStep}>
                        {dependencyInjection?.onboarding.OnboardingButtonIcon()}
                        {t("onboarding__cities__add-my-top-cities")}
                    </Lib.Button.Onboarding>
                </Lib.Flex.Column>
            </Lib.Flex.Column>
        </Transition>
    );
}

interface ChoseCitiesScreenProps {
    goToNextStep: () => void;
    topCities: OnboardingTopCityList;
    selectedCities: OnboardingTopCityList;
    toggleCitySelection: (city: any) => void;
    onSelect: (city: any) => void;
}

function ChooseCitiesScreen(props: ChoseCitiesScreenProps) {
    const {
        goToNextStep,
        topCities,
        selectedCities,
        toggleCitySelection,
        onSelect,
    } = props;
    const t = useAlbertineTranslation();
    const [isActive, setIsActive] = useState(false);

    const areAllCitiesSelected = selectedCities.length === 3;
    const onInputChange = (isInputActive: boolean) => {
        setIsActive(isInputActive);
    };

    return (
        <Transition>
            <Lib.Flex.Column
                gap="value8"
                className="onboarding-cities__container"
            >
                <Lib.Tag.Primary>
                    {t("onboarding__cities__tag")}
                </Lib.Tag.Primary>
                <Lib.Flex.Column gap="value16">
                    <Lib.Flex.Column gap="value24">
                        {!areAllCitiesSelected ? (
                            <>
                                <Lib.Paragraph.XXLarge.XBold>
                                    {t(
                                        "onboarding__cities__choose-up-to-three",
                                    )}
                                </Lib.Paragraph.XXLarge.XBold>

                                <TopCitySearchInputField
                                    selectedCities={selectedCities}
                                    areAllCitiesSelected={areAllCitiesSelected}
                                    onSelect={onSelect}
                                    onInputChange={onInputChange}
                                />

                                {!isActive && (
                                    <>
                                        <Lib.Label.Medium.Bold>
                                            {t(
                                                "onboarding__cities__most-popular",
                                            )}
                                        </Lib.Label.Medium.Bold>

                                        <Lib.Flex.Column
                                            gap="value16"
                                            className={classnames(
                                                "onboarding-cities__list",
                                                {
                                                    "onboarding-cities__list__overscroll":
                                                        selectedCities.length >
                                                            0 &&
                                                        selectedCities.length <
                                                            3,
                                                    "onboarding-cities__list__overscroll--full":
                                                        selectedCities.length ===
                                                        maxCitiesSelected,
                                                },
                                            )}
                                        >
                                            {topCities.map((city) => {
                                                const isSelected =
                                                    selectedCities.some(
                                                        (c) =>
                                                            c.googlePlaceId ===
                                                            city.googlePlaceId,
                                                    );
                                                return (
                                                    <Lib.Flex.Row
                                                        key={city.googlePlaceId}
                                                        onClick={() =>
                                                            toggleCitySelection(
                                                                city,
                                                            )
                                                        }
                                                    >
                                                        {isSelected ? (
                                                            <Lib.Button.Ghost
                                                                onClick={() =>
                                                                    toggleCitySelection(
                                                                        city,
                                                                    )
                                                                }
                                                            >
                                                                <Lib.Icon.Check />
                                                                <Lib.Label.Large.Bold>
                                                                    {city.city}
                                                                </Lib.Label.Large.Bold>
                                                                <Lib.Label.Medium.Bold>
                                                                    {
                                                                        city.country
                                                                    }
                                                                </Lib.Label.Medium.Bold>
                                                            </Lib.Button.Ghost>
                                                        ) : (
                                                            <Lib.Button.Ghost
                                                                onClick={() =>
                                                                    toggleCitySelection(
                                                                        city,
                                                                    )
                                                                }
                                                            >
                                                                <div />
                                                                <Lib.Label.Large.Reqular>
                                                                    {city.city}
                                                                </Lib.Label.Large.Reqular>
                                                                <Lib.Label.Medium.Reqular>
                                                                    {
                                                                        city.country
                                                                    }
                                                                </Lib.Label.Medium.Reqular>
                                                            </Lib.Button.Ghost>
                                                        )}
                                                    </Lib.Flex.Row>
                                                );
                                            })}
                                        </Lib.Flex.Column>
                                    </>
                                )}
                            </>
                        ) : (
                            <Lib.Flex.Row gap="value8">
                                <Lib.Paragraph.XXLarge.Bold>
                                    {t(
                                        "onboarding__cities__all-three-selected",
                                    )}
                                </Lib.Paragraph.XXLarge.Bold>
                                <Lib.Icon.Check />
                            </Lib.Flex.Row>
                        )}

                        {selectedCities.length > 0 && (
                            <Lib.Flex.Column gap="value16">
                                <Lib.Line.Dashed />
                                <Lib.Flex.Row alignItems="center">
                                    {selectedCities.map(
                                        (city: OnboardingTopCityListItem) => (
                                            <div
                                                key={city.googlePlaceId}
                                                onClick={() =>
                                                    toggleCitySelection(city)
                                                }
                                                className="onboarding-selected-city text-color-inversable"
                                            >
                                                <Lib.Label.XSmall.Bold>
                                                    {city.city}
                                                </Lib.Label.XSmall.Bold>
                                                <Lib.Icon.Close.Small.Gray100 />
                                            </div>
                                        ),
                                    )}
                                </Lib.Flex.Row>
                                <Lib.Button.PrimaryBackground
                                    className="onboarding-button"
                                    onClick={goToNextStep}
                                >
                                    {t("onboarding__cities__done")}
                                </Lib.Button.PrimaryBackground>
                            </Lib.Flex.Column>
                        )}
                    </Lib.Flex.Column>
                </Lib.Flex.Column>
            </Lib.Flex.Column>
        </Transition>
    );
}

interface CityRecommendationsScreenProps {
    onContinue: () => void;
    goToPreviousStep: () => void;
    selectedCities: OnboardingTopCityList;
}

function CityRecommendationsScreen(props: CityRecommendationsScreenProps) {
    const { onContinue, goToPreviousStep, selectedCities } = props;
    const t = useAlbertineTranslation();

    return (
        <Transition>
            <Lib.Flex.Column gap="value24">
                <Lib.Flex.Column gap="value12">
                    <Lib.Flex.Row>
                        <Lib.Flex.Column>
                            <Lib.Tag.Primary>
                                {t("onboarding__cities__tag")}
                            </Lib.Tag.Primary>
                        </Lib.Flex.Column>
                        <Lib.Flex.Column
                            justifyContent="center"
                            alignItems="end"
                        >
                            <Lib.Button.Ghost onClick={goToPreviousStep}>
                                <Lib.Icon.LocationOn />
                                <Lib.Label.Medium.Bold>
                                    {t("onboarding__cities__edit")}
                                </Lib.Label.Medium.Bold>
                            </Lib.Button.Ghost>
                        </Lib.Flex.Column>
                    </Lib.Flex.Row>

                    <Lib.Flex.Row gap="value4">
                        {selectedCities.length > 0 &&
                            selectedCities.map(
                                (city: OnboardingTopCityListItem) => (
                                    <div
                                        className="onboarding-cities__recommendations-city"
                                        key={city.id}
                                    >
                                        <Lib.RemoteImage.Square.Medium
                                            src={city.image}
                                            alt={city.city}
                                            className="onboarding-cities__recommendations-city__image"
                                        />
                                        <Lib.Flex.Row className="onboarding-cities__recommendations-city__title text-color-inversable">
                                            <Lib.Heading.H6.Bold>
                                                {city.city}
                                            </Lib.Heading.H6.Bold>
                                        </Lib.Flex.Row>
                                    </div>
                                ),
                            )}
                    </Lib.Flex.Row>
                </Lib.Flex.Column>
                <Lib.Flex.Column gap="value8">
                    <Lib.Paragraph.XXLarge.XBold>
                        {t("onboarding__cities__recommendations")}
                    </Lib.Paragraph.XXLarge.XBold>
                    <Lib.Paragraph.Small.MediumBold>
                        {t("onboarding__cities__location-sharing")}
                    </Lib.Paragraph.Small.MediumBold>
                </Lib.Flex.Column>

                <Lib.Button.Onboarding type="button" onClick={onContinue}>
                    {t("onboarding__cities__continue")}
                </Lib.Button.Onboarding>
            </Lib.Flex.Column>
        </Transition>
    );
}

function OnboardCities() {
    const [step, setStep] = useState<number>(0);
    const [topCities, setTopCities] = useState<OnboardingTopCityList>([]);
    const [selectedCities, setSelectedCities] = useState<OnboardingTopCityList>(
        [],
    );

    const { member, updateMemberPreferences, setNextStep, setProgress } =
        useOutletContext<OnboardingOutletContextType>();

    useEffect(() => {
        setProgress(45);
        fetchTopCities()
            .then((res) => setTopCities(res))
            .catch((error) => logError("fetchTopCities", error));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const goToPreviousStep = () => {
        setStep(step - 1);
    };

    const goToNextStep = () => {
        setStep(step + 1);
    };

    const toggleCitySelection = (city: OnboardingTopCityListItem) => {
        setSelectedCities((prevSelectedCities: OnboardingTopCityList) => {
            const isSelected = prevSelectedCities.some(
                (c: OnboardingTopCityListItem) => c.id === city.id,
            );

            if (!isSelected && prevSelectedCities.length >= 3) {
                return prevSelectedCities;
            }

            return isSelected
                ? prevSelectedCities.filter((c) => c.id !== city.id)
                : [...prevSelectedCities, city];
        });
    };

    const onSelectCity = (city: OnboardingTopCityListItem) => {
        setSelectedCities((prevCities) => [...prevCities, city]);
    };

    async function handleLocationPermission() {
        await requestLocationPermission();

        updateMemberPreferences(
            { onboarding: { topCities: selectedCities } },
            member!,
        )
            .then(() => setNextStep("conciergeExperience"))
            .catch((error) => {
                logError("updateMemberPreferences", error);
            });
    }

    return (
        <>
            {step === 0 && <InitialScreen goToNextStep={goToNextStep} />}
            {step === 1 && (
                <ChooseCitiesScreen
                    goToNextStep={goToNextStep}
                    topCities={topCities}
                    selectedCities={selectedCities}
                    toggleCitySelection={toggleCitySelection}
                    onSelect={onSelectCity}
                />
            )}
            {step === 2 && (
                <CityRecommendationsScreen
                    onContinue={() => handleLocationPermission()}
                    goToPreviousStep={goToPreviousStep}
                    selectedCities={selectedCities}
                />
            )}
        </>
    );
}

export default OnboardCities;
