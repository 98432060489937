import React, { useEffect, useState } from "react";
import {
    Lib,
    Trans,
    useAlbertineTranslation,
    useTenant,
} from "albertine-shared-web";
import CountryCodes from "./CountryCodes";
import { CodeInput, PhoneNumberInput } from "./NumberInput";
import EmailInput from "./EmailInput";
import { PhoneNumber } from "../types/PhoneNumber";
import "./LoginFormStep.css";

type PhoneNumberStepProps = {
    phoneNumber: PhoneNumber;
    setPhoneNumber: (value: PhoneNumber) => void;
    onButton: () => void;
};

export function PhoneNumberStep({
    phoneNumber,
    setPhoneNumber,
    onButton,
}: PhoneNumberStepProps) {
    const t = useAlbertineTranslation();
    const { tenant } = useTenant();

    const [disabled, setDisabled] = useState(false);
    const [myPhoneNumber, setMyPhoneNumber] =
        useState<PhoneNumber>(phoneNumber);

    useEffect(() => {
        setDisabled(!myPhoneNumber.isValid);
        setPhoneNumber(myPhoneNumber);
    }, [myPhoneNumber, setPhoneNumber]);

    const ButtonComponent =
        tenant === "hugoboss"
            ? Lib.Button.PrimaryBackground
            : Lib.Button.Primary;

    return (
        <Lib.Flex.Column gap="value8">
            <Lib.Flex.Column gap="value16">
                <Lib.Paragraph.Medium.Reqular.TextColor02>
                    {t("login_form__description_sms")}
                </Lib.Paragraph.Medium.Reqular.TextColor02>

                <Lib.Line.Dashed />

                <Lib.Flex.Column gap="value8">
                    <Lib.Flex.Row className="login_form_step__form-fields-container">
                        <CountryCodes
                            phoneNumber={myPhoneNumber}
                            setPhoneNumber={(value) =>
                                setMyPhoneNumber((prev) => ({
                                    ...prev,
                                    ...value,
                                }))
                            }
                        />
                        <PhoneNumberInput
                            phoneNumber={myPhoneNumber}
                            setPhoneNumber={(value) =>
                                setMyPhoneNumber((prev) => ({
                                    ...prev,
                                    ...value,
                                }))
                            }
                            onContinue={onButton}
                        />
                    </Lib.Flex.Row>
                </Lib.Flex.Column>
            </Lib.Flex.Column>

            <Lib.Flex.Column gap="value16" alignItems="center">
                <ButtonComponent
                    disabled={disabled}
                    onClick={onButton}
                    label={t("login_form__continue")}
                    className="login_form_step__submit-button"
                />
            </Lib.Flex.Column>
        </Lib.Flex.Column>
    );
}

export type LoginStep = "intro" | "phone" | "code" | "email" | "link";

interface LoginFormStepProps {
    type: LoginStep;
    descriptionText: string;
    buttonText: string;
    buttonDisabled?: boolean;
    currentValue?: string;
    loading?: boolean;
    onButton: () => void;
    onTyping?: (value: string) => void;
    onEmailLink?: () => void;
}

function LoginFormStep({
    type,
    descriptionText,
    buttonText,
    buttonDisabled = false,
    currentValue = "",
    loading,
    onButton,
    onTyping,
    onEmailLink,
}: LoginFormStepProps) {
    const { tenant, tenantConfig } = useTenant();
    const t = useAlbertineTranslation();

    // TODO: Use dependency injection or CSS variables to set the button style.
    const ButtonComponent =
        tenant === "hugoboss"
            ? Lib.Button.PrimaryBackground
            : Lib.Button.Primary;

    const supportEmailLink = `mailto:${tenantConfig.emailService}`;

    return (
        <Lib.Flex.Column gap="value8">
            <Lib.Flex.Column gap="value16">
                <Lib.Paragraph.Medium.Reqular.TextColor02>
                    {type !== "link" && descriptionText}

                    {type === "link" && (
                        <Trans
                            i18nKey="login_form__description_link"
                            components={[
                                // eslint-disable-next-line jsx-a11y/anchor-has-content
                                <a
                                    key="supportEmail"
                                    href={supportEmailLink}
                                    className="login_form_step__email_in_text"
                                />,
                            ]}
                            values={{ linkText: tenantConfig.emailService }}
                        />
                    )}
                </Lib.Paragraph.Medium.Reqular.TextColor02>

                {type !== "link" && <Lib.Line.Dashed />}

                <Lib.Flex.Column gap="value8">
                    {type === "code" && (
                        <Lib.Flex.Column gap="value24">
                            <CodeInput
                                code={currentValue}
                                setCode={(value) => onTyping?.(value)}
                                loading={loading}
                            />

                            <Lib.Button.Ghost onClick={onEmailLink}>
                                <Lib.Label.Medium.MediumBold.TextColor01>
                                    {t("login_form__email_login_link_text")}
                                </Lib.Label.Medium.MediumBold.TextColor01>
                            </Lib.Button.Ghost>
                        </Lib.Flex.Column>
                    )}
                    {type === "email" && (
                        <EmailInput
                            onChange={(event) => onTyping?.(event.target.value)}
                            placeholder="your@email.com"
                            loading={loading}
                            defaultValue={currentValue}
                        />
                    )}
                </Lib.Flex.Column>
            </Lib.Flex.Column>

            <Lib.Flex.Column gap="value16" alignItems="center">
                {type !== "code" && (
                    <ButtonComponent
                        disabled={buttonDisabled}
                        onClick={onButton}
                        label={buttonText}
                        className="login_form_step__submit-button"
                    />
                )}
            </Lib.Flex.Column>
        </Lib.Flex.Column>
    );
}

export default LoginFormStep;
