import { isSameDay, startOfDay } from "date-fns";
import { Message } from "../../../lmt/src/common/types/Message";
import { fromTimestampToDate } from "./timestamp.util";

// TODO: Add util tests

export type ChatMessage = {
    message: Message;
    // sendingState: "sending" | "sent" | "failed"; // TODO
    showSenderRow: boolean;
};

export type MessageGroup = {
    messages: Message[];
    date: Date;
};

export type ChatMessageGroup = {
    messages: ChatMessage[];
    date: Date;
};

function separateMessagesToGroups(messages: Message[]): MessageGroup[] {
    return messages.reduce((groups: MessageGroup[], message) => {
        const lastGroup = groups[groups.length - 1];
        const lastMessageInLastGroup =
            lastGroup?.messages[lastGroup.messages.length - 1];
        const messageDate = fromTimestampToDate(message.sentAt);
        if (!lastMessageInLastGroup) {
            return [
                {
                    messages: [message],
                    date: startOfDay(messageDate),
                },
            ] as MessageGroup[];
        }
        const lastMessageDate = fromTimestampToDate(
            lastMessageInLastGroup.sentAt,
        );
        if (isSameDay(lastMessageDate, messageDate)) {
            return [
                ...groups.slice(0, -1),
                {
                    messages: [...lastGroup.messages, message],
                    date: lastGroup.date,
                },
            ];
        }
        return [
            ...groups,
            {
                messages: [message],
                date: startOfDay(messageDate),
            },
        ];
    }, []);
}

function messagesAreWithin10minutes(
    messageSentAt: Date,
    nextMessageSentAt: Date | undefined,
): boolean {
    if (!nextMessageSentAt) return false;
    const tenMinutes = 10 * 60 * 1000;
    return (
        Math.abs(messageSentAt.getTime() - nextMessageSentAt.getTime()) <
        tenMinutes
    );
}

export function messagesToChatMessages(messages: Message[]): ChatMessage[] {
    return messages.map((message, index) => {
        const previousMessage = messages[index - 1];
        const insideTimeFrame = messagesAreWithin10minutes(
            fromTimestampToDate(message.sentAt),
            previousMessage
                ? fromTimestampToDate(previousMessage.sentAt)
                : undefined,
        );
        const sameSender = previousMessage?.sentBy === message.sentBy;
        const sameRequest = previousMessage?.request === message.request;
        const showSenderRow = !insideTimeFrame || !sameSender || !sameRequest;

        return {
            message,
            showSenderRow,
        };
    });
}

export function messagesToChatMessageGroups(
    messages: Message[],
): ChatMessageGroup[] {
    const groups = separateMessagesToGroups(messages);
    return groups.map((group) => {
        const chatMessages = messagesToChatMessages(group.messages);
        return {
            messages: chatMessages,
            date: group.date,
        };
    });
}
