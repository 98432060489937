import { z } from "zod";

export const PhoneNumber = z.object({
    // International dial code with a leading "+".
    dialCode: z.string().regex(/^\+\d+$/),
    // ISO 3166-1 alpha-2 country code.
    countryCode: z.string().length(2),
    // Phone number without the country code.
    phoneNumber: z.string(),
    // E.164 formatted phone number.
    parsedPhoneNumber: z.string(),
    // Whether the phone number is valid.
    isValid: z.boolean(),
});

export type PhoneNumber = z.infer<typeof PhoneNumber>;

// Data needed for country code picking.
export const PhoneNumberCountryCode = PhoneNumber.pick({
    dialCode: true,
    countryCode: true,
});

export type PhoneNumberCountryCode = z.infer<typeof PhoneNumberCountryCode>;
