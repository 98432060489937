import { useEffect, useState } from "react";
import { listenToQueryToState } from "../api/listeners";
import {
    conversationMessageDocument,
    queryConversationMessages,
    queryLastMessageInRequest,
    queryNewConversationMessages,
} from "../api/firestore";
import { Message } from "../../../lmt/src/common/types/Message";
import { fromTimestampToDate } from "../utils/timestamp.util";
import { loadDocument, loadDocuments } from "../api/loaders";

const pageLength = 100;
export function ListenToMessagesPaged(props: {
    conversationId: string;
    requestId?: string | undefined;
    requestCreatedFromMessage: string | undefined;
    children: (
        messages: Message[],
        isLoading: boolean,
        loadMore: (() => Promise<void>) | undefined,
    ) => JSX.Element;
}) {
    const { conversationId, requestId, requestCreatedFromMessage, children } =
        props;
    const [newMessages, setNewMessages] = useState<Message[] | undefined>();
    const [pastMessageState, setPastMessageState] = useState<{
        pastMessages: Message[] | undefined;
        isLoading: boolean;
        canLoadMore: boolean;
    }>({
        pastMessages: undefined,
        isLoading: false,
        canLoadMore: true,
    });

    const clearState = () => {
        setPastMessageState({
            pastMessages: undefined,
            isLoading: false,
            canLoadMore: false,
        });
    };

    const loadMore = async () => {
        if (!conversationId) return;
        if (pastMessageState.isLoading) return;
        setPastMessageState({ ...pastMessageState, isLoading: true });
        const { pastMessages } = pastMessageState;
        const lastLoadedMessage = pastMessages?.[pastMessages.length - 1];
        const lastLoadedMessageSentAt = lastLoadedMessage
            ? fromTimestampToDate(lastLoadedMessage.sentAt)
            : undefined;
        const query = queryConversationMessages(
            conversationId,
            requestId,
            lastLoadedMessageSentAt,
            pageLength,
        );
        const messages = await loadDocuments(query, Message);
        const mergeLastMessages = [
            ...(pastMessageState.pastMessages ?? []),
            ...messages,
        ];

        const hasReachedTheEnd = messages.length < pageLength;
        if (hasReachedTheEnd && requestCreatedFromMessage) {
            const containsRequestCreatedFromMessage = messages.some(
                (message) => message.id === requestCreatedFromMessage,
            );
            if (!containsRequestCreatedFromMessage) {
                const createdFromMessage = await loadDocument(
                    conversationMessageDocument(
                        conversationId,
                        requestCreatedFromMessage,
                    ),
                    Message,
                );
                if (createdFromMessage) {
                    mergeLastMessages.push(createdFromMessage);
                }
            }
        }

        setPastMessageState({
            pastMessages: mergeLastMessages,
            isLoading: false,
            canLoadMore: !hasReachedTheEnd,
        });
    };

    useEffect(() => {
        if (!conversationId) {
            setNewMessages(undefined);
        }
        return listenToQueryToState<Message>(
            queryNewConversationMessages(conversationId, requestId),
            setNewMessages,
        );
    }, [conversationId, requestId]);

    useEffect(() => {
        if (!conversationId) {
            clearState();
            return undefined;
        }
        loadMore();
        return clearState;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversationId]);

    const { pastMessages, isLoading, canLoadMore } = pastMessageState;
    const allMessages = [...(newMessages ?? []), ...(pastMessages ?? [])];
    return children(allMessages, isLoading, canLoadMore ? loadMore : undefined);
}

export function ListenToLatestMessageInThread(props: {
    conversationId: string;
    requestId: string;
    children: (message: Message | undefined) => JSX.Element;
}) {
    const { conversationId, requestId, children } = props;
    const [messages, setMessages] = useState<Message[] | undefined>();

    useEffect(
        () =>
            listenToQueryToState<Message>(
                queryLastMessageInRequest(conversationId, requestId),
                setMessages,
            ),
        [conversationId, requestId],
    );

    return children(messages?.[0]);
}
