import OnboardingStart from "./OnboardingStart";
import OnboardingName from "./OnboardingName";
import OnboardingCities from "./OnboardingCities";
import OnboardingConciergeExperience from "./OnboardingConciergeExperience";
import { OnboardingSteps } from "../../types/OnboardingSteps";
import OnboardingTravelPlans from "./OnboardingTravelPlans";
import OnboardingDiningPlans from "./OnboardingDiningPlans";

type OnboardingStep = JSX.ElementType;
type StepsMap = {
    [key in OnboardingSteps]: OnboardingStep;
};

const onboardingStepsMap: StepsMap = {
    start: OnboardingStart,
    name: OnboardingName,
    cities: OnboardingCities,
    conciergeExperience: OnboardingConciergeExperience,
    travelPlans: OnboardingTravelPlans,
    diningPlans: OnboardingDiningPlans,
};

export default onboardingStepsMap;
