import { z } from "zod";
import { AdditionalServiceLanguage } from "albertine-shared-web";
import { LongString } from "../validate";
import { capitalize } from "../utils/string.util";

export const catalogItemNameMaxLength = 255;
export const CatalogItemEditableTranslation = z.object({
    address: LongString.nullable(),
    benefits: z.string().nullable(),
    bookingPolicy: z.string().nullable(),
    description: z
        .string({ required_error: "Add general description" })
        .min(3, "Add general description")
        .nullable(),
    eventDates: z.string().nullable(),
    headline: z.string().nullable(),
    localInsights: z.string().nullable(),
    name: z
        .string({ required_error: "Add name" })
        .min(3, "Add name")
        .max(catalogItemNameMaxLength, "Limit for name is 255 characters")
        .nullable(),
    travelItineraryNotes: z.string().nullable(),
});
export const CatalogItemDerivedTranslation = z.object({
    keyDetail: z.string().nullable(),
});

export const CatalogItemTranslation = CatalogItemEditableTranslation.merge(
    CatalogItemDerivedTranslation,
);

export type CatalogItemEditableTranslationKeys =
    keyof CatalogItemEditableTranslation;

type CatalogItemTranslationAdditionalLanguageKeys =
    `${CatalogItemEditableTranslationKeys}${Capitalize<AdditionalServiceLanguage>}`;

export type CatalogItemTranslationServiceLanguageKeys =
    `${CatalogItemEditableTranslationKeys}${Capitalize<"" | AdditionalServiceLanguage>}`;

export const CatalogItemTranslations = z.object({
    de: CatalogItemTranslation.optional(),
    fr: CatalogItemTranslation.optional(),
});

export type CatalogItemTranslation = z.infer<typeof CatalogItemTranslation>;
export type CatalogItemEditableTranslation = z.infer<
    typeof CatalogItemEditableTranslation
>;
export type CatalogItemDerivedTranslation = z.infer<
    typeof CatalogItemDerivedTranslation
>;
export type CatalogItemTranslations = z.infer<typeof CatalogItemTranslations>;

export function collectTranslationValues(
    keys: CatalogItemEditableTranslationKeys[],
    item: Record<string, any>,
    language: AdditionalServiceLanguage | "all",
): (string | null | undefined)[] {
    const formPropertyName = (
        lang: AdditionalServiceLanguage,
        key: CatalogItemEditableTranslationKeys,
    ): CatalogItemTranslationAdditionalLanguageKeys =>
        `${key}${capitalize(lang) as Capitalize<AdditionalServiceLanguage>}`;

    const translationKeys =
        language === "all"
            ? AdditionalServiceLanguage.options.flatMap(
                  (lang): CatalogItemTranslationAdditionalLanguageKeys[] =>
                      keys.map<CatalogItemTranslationAdditionalLanguageKeys>(
                          (key) => formPropertyName(lang, key),
                      ),
              )
            : keys.map<CatalogItemTranslationAdditionalLanguageKeys>((key) =>
                  formPropertyName(language, key),
              );

    return translationKeys.map((key) => item[key]);
}
