import { z } from "zod";
import { ServiceLanguage } from "albertine-shared-web";
import {
    AddressComponents,
    CatalogItem,
    getItemKeyDetail,
} from "../CatalogItem";
import { Tag } from "../tags";
import { excludeUndefined } from "../utils/array.util";
import { isString } from "../utils/string.util";
import { encodeKeyValuePair } from "../utils/url.util";
import { IsoDateTimeString } from "../validate";
import { OfferingCategory } from "./OfferingCategory";
import {
    OpenSearchHit,
    OpenSearchQuery,
    OpenSearchResponse,
    OpenSearchVisibilityFilter,
} from "./OpenSearch";
import { CatalogItemTranslation } from "./Translation";
import { isTypeOf } from "../utils/zod.util";

const CatalogLocationFilter = z.object({
    administrativeAreaLevel1: z.string().optional(),
    administrativeAreaLevel2: z.string().optional(),
    locality: z.string().optional(),
    sublocalityLevel1: z.string().optional(),
    neighborhood: z.string().optional(),
    country: z.string().optional(),
    lat: z.number().optional(),
    lon: z.number().optional(),
});

export type CatalogLocationFilter = z.infer<typeof CatalogLocationFilter>;

export type CatalogPrimaryFilters = {
    searchTerm: string | undefined;
    offeringCategories: OfferingCategory[] | undefined;
    locationSearchTerm: string | undefined;
    location: CatalogLocationFilter | undefined;
};

export type CatalogQuerySecondaryFilters = {
    source: string[] | undefined;
    suppliers: string[] | undefined;
    recentlyOpened: boolean | undefined;
    albertineVerified: boolean | undefined;
    missingTranslations: boolean | undefined;
    tags: Tag[] | undefined;
    published: OpenSearchVisibilityFilter[] | undefined;
};

export type CatalogQueryFilters = {
    primaryFilters: CatalogPrimaryFilters;
    secondaryFilters: CatalogQuerySecondaryFilters;
};

export const OpenSearchCatalogHitSource = z.object({
    id: z.string(),
    googlePlaceId: z.string().optional().nullable(),
    name: z.string(),
    description: z.string().nullable(),
    address: z.string().nullable(),
    website: z.string().nullable(),
    offeringCategories: z.array(OfferingCategory).optional().nullable(),
    suppliers: z.array(z.string()).optional().nullable(),
    tags: z.array(z.string()).optional().nullable(),
    notes: z.string().optional().nullable(),
    images: z.array(z.string()).optional().nullable(),
    localInsights: z.string().optional().nullable(),
    source: z.string().optional().nullable(),
    location: CatalogLocationFilter.merge(
        z.object({
            point: z.object({
                lat: z.number(),
                lon: z.number(),
            }),
            countryShort: z.string().optional(),
            postalTown: z.string().optional(),
            sublocalityLevel2: z.string().optional(),
            streetAddress: z.string().optional(),
        }),
    ).optional(),
    published: z.boolean(),
    shared: z.boolean(),
    createdAt: z.string(),
    albertineVerified: IsoDateTimeString.optional().nullable(),
    recentlyOpened: IsoDateTimeString.optional().nullable(),
    slug: z.string().nullable(),
    cityGuideLocation: z.string().nullable(),
    headline: z.string().nullable(),
    benefits: z.string().nullable(),
    bookingPolicy: z.string().nullable(),
    eventDates: z.string().nullable(),
    travelItineraryNotes: z.string().nullable(),
    keyDetail: z.string().nullable(),
    translations: z
        .object({
            de: CatalogItemTranslation.optional(),
            fr: CatalogItemTranslation.optional(),
        })
        .optional(),
});

const OpenSearchCatalogHit = OpenSearchHit(OpenSearchCatalogHitSource);

export type OpenSearchCatalogHit = z.infer<typeof OpenSearchCatalogHit>;

const SearchResultHit = OpenSearchCatalogHitSource.merge(
    OpenSearchCatalogHit.pick({ sort: true }),
).merge(z.object({ id: z.string() }));

type SearchResultHit = z.infer<typeof SearchResultHit>;

export type SelectedCatalogHit = Pick<
    SearchResultHit,
    "id" | "name" | "images" | "offeringCategories" | "location" | "sort"
>;

export type OpenSearchCatalogHitSource = z.infer<
    typeof OpenSearchCatalogHitSource
>;

export const OpenSearchCatalogResponse = OpenSearchResponse(
    OpenSearchCatalogHitSource,
);

export type OpenSearchCatalogResponse = z.infer<
    typeof OpenSearchCatalogResponse
>;

export function addressComponentsToOpenSearchLocationFilter(
    addressComponents: AddressComponents | undefined,
    lat?: number,
    lon?: number,
): CatalogLocationFilter | undefined {
    if (addressComponents) {
        const administrativeAreaLevel1 = addressComponents.find((it) =>
            it.types.includes("administrative_area_level_1"),
        )?.longName;
        const administrativeAreaLevel2 = addressComponents.find((it) =>
            it.types.includes("administrative_area_level_2"),
        )?.longName;
        const locality = addressComponents.find((it) =>
            it.types.includes("locality"),
        )?.longName;
        const sublocalityLevel1 = addressComponents.find((it) =>
            it.types.includes("sublocality_level_1"),
        )?.longName;
        const neighborhood = addressComponents.find((it) =>
            it.types.includes("neighborhood"),
        )?.longName;
        const country = addressComponents.find((it) =>
            it.types.includes("country"),
        )?.longName;

        if (country) {
            return {
                administrativeAreaLevel1,
                administrativeAreaLevel2,
                sublocalityLevel1,
                locality,
                neighborhood,
                country,
                lat,
                lon,
            };
        }
    }

    return undefined;
}

export function catalogLocationToOpenSearchLocation(
    catalogItem: CatalogItem,
): Pick<OpenSearchCatalogHitSource, "location"> {
    const { addressComponents, geoPoint } = catalogItem;
    const components =
        addressComponentsToOpenSearchLocationFilter(addressComponents);

    return geoPoint?.latitude && geoPoint?.longitude
        ? {
              location: {
                  point: {
                      lat: geoPoint.latitude,
                      lon: geoPoint.longitude,
                  },
                  administrativeAreaLevel1:
                      components?.administrativeAreaLevel1 || "",
                  administrativeAreaLevel2:
                      components?.administrativeAreaLevel2 || "",
                  locality: components?.locality || "",
                  sublocalityLevel1: components?.sublocalityLevel1 || "",
                  neighborhood: components?.neighborhood || "",
                  country: components?.country || "",
              },
          }
        : { location: undefined };
}

export function catalogItemToIndexDocument(
    catalogItem: CatalogItem,
): OpenSearchCatalogHitSource {
    const { storyblokArticleSlug: slug } = catalogItem;

    const openSearchLocation =
        catalogLocationToOpenSearchLocation(catalogItem).location;

    const tags = isTypeOf(catalogItem.tags, z.array(Tag))
        ? catalogItem.tags
        : [];
    const keyDetailDe = catalogItem.keyDetail
        ? getItemKeyDetail(
              catalogItem.offeringCategories,
              "de",
              tags,
              catalogItem.travelItineraryNotesDe,
              catalogItem.eventDatesDe,
              catalogItem.cityGuideLocation,
          )
        : null;
    const keyDetailFr = catalogItem.keyDetail
        ? getItemKeyDetail(
              catalogItem.offeringCategories,
              "fr",
              tags,
              catalogItem.travelItineraryNotesDe,
              catalogItem.eventDatesDe,
              catalogItem.cityGuideLocation,
          )
        : null;

    return {
        id: catalogItem.id,
        googlePlaceId: catalogItem.googlePlaceId,
        name: catalogItem.name,
        description: catalogItem.description || "",
        address: catalogItem.address || "",
        benefits: catalogItem.benefits || null,
        bookingPolicy: catalogItem.bookingPolicy || null,
        eventDates: catalogItem.eventDates || null,
        localInsights: catalogItem.localInsights || "",
        website: catalogItem.website || null,
        offeringCategories: catalogItem.offeringCategories,
        suppliers: catalogItem.suppliers,
        notes: catalogItem.notes || "",
        tags: catalogItem.tags,
        images: catalogItem.images
            ? catalogItem.images.map((it) => it.url)
            : undefined,
        source: catalogItem.source || "",
        published: catalogItem.published || false,
        shared: catalogItem.shared || false,
        ...(openSearchLocation ? { location: openSearchLocation } : {}),
        createdAt: catalogItem.createdAt,
        albertineVerified: catalogItem.albertineVerified || null,
        recentlyOpened: catalogItem.recentlyOpened || null,
        slug: slug || null,
        cityGuideLocation: catalogItem.cityGuideLocation
            ? catalogItem.cityGuideLocation.key
            : null,
        headline: catalogItem.headline || null,
        travelItineraryNotes: catalogItem.travelItineraryNotes || null,
        translations: {
            de: {
                address: catalogItem.addressDe || null,
                benefits: catalogItem.benefitsDe || null,
                bookingPolicy: catalogItem.bookingPolicyDe || null,
                description: catalogItem.descriptionDe || null,
                eventDates: catalogItem.eventDatesDe || null,
                localInsights: catalogItem.localInsightsDe || null,
                headline: catalogItem.headlineDe || null,
                name: catalogItem.nameDe || null,
                travelItineraryNotes:
                    catalogItem.travelItineraryNotesDe || null,
                keyDetail: keyDetailDe,
            },
            fr: {
                address: catalogItem.addressFr || null,
                benefits: catalogItem.benefitsFr || null,
                bookingPolicy: catalogItem.bookingPolicyFr || null,
                description: catalogItem.descriptionFr || null,
                eventDates: catalogItem.eventDatesFr || null,
                localInsights: catalogItem.localInsightsFr || null,
                headline: catalogItem.headlineFr || null,
                name: catalogItem.nameFr || null,
                travelItineraryNotes:
                    catalogItem.travelItineraryNotesFr || null,
                keyDetail: keyDetailFr,
            },
        },
        keyDetail: catalogItem.keyDetail || null,
    };
}

export function moreStrictLocationSearchThanCountry(
    filters: Pick<CatalogPrimaryFilters, "location">,
) {
    const { location } = filters;
    return (
        location?.administrativeAreaLevel1 ||
        location?.administrativeAreaLevel2 ||
        location?.locality ||
        location?.neighborhood ||
        location?.sublocalityLevel1
    );
}

type MustPublishedFilter = [
    {
        match: {
            published: true;
        };
    },
];

const mustPublishedFilter: MustPublishedFilter = [
    {
        match: {
            published: true,
        },
    },
];

interface SlugsFilter {
    terms: {
        slug: string[];
    };
}

interface CityGuideLocationFilter {
    terms: {
        cityGuideLocation: string[];
    };
}

interface AlbertineVerifiedFilter {
    exists: {
        field: "albertineVerified";
    };
}

interface OfferingCategoriesFilter {
    terms: {
        offeringCategories: OfferingCategory[];
    };
}

interface RecentlyOpenedFilter {
    range: {
        recentlyOpened: {
            gte: "now-3M";
        };
    };
}

type Filter =
    | SlugsFilter
    | CityGuideLocationFilter
    | AlbertineVerifiedFilter
    | OfferingCategoriesFilter
    | RecentlyOpenedFilter;

function filterByCityGuideLocation(keys: string[]): CityGuideLocationFilter {
    return {
        terms: {
            cityGuideLocation: keys,
        },
    };
}

const filterByAlbertineVerified: AlbertineVerifiedFilter = {
    exists: {
        field: "albertineVerified",
    },
};

function filterBySlugs(slugs: string[]): SlugsFilter {
    return {
        terms: {
            slug: slugs,
        },
    };
}

function filterByCategories(
    offeringCategories: OfferingCategory[],
): OfferingCategoriesFilter {
    return {
        terms: {
            offeringCategories,
        },
    };
}

const filterByRecentlyOpened: RecentlyOpenedFilter = {
    range: {
        recentlyOpened: {
            gte: "now-3M",
        },
    },
};

interface SortByPublished {
    published: {
        order: "desc";
    };
}

interface SortByRecentlyOpened {
    recentlyOpened: {
        order: "desc";
    };
}

interface SortByAlbertineVerified {
    albertineVerified: {
        order: "desc";
    };
}

type SortBy = SortByPublished | SortByRecentlyOpened | SortByAlbertineVerified;

const sortByPublished: SortByPublished = {
    published: {
        order: "desc",
    },
};

const sortByRecentlyOpened: SortByRecentlyOpened = {
    recentlyOpened: {
        order: "desc",
    },
};

const sortByAlbertineVerified: SortByAlbertineVerified = {
    albertineVerified: {
        order: "desc",
    },
};

interface ThemeQuery extends OpenSearchQuery {
    query: {
        bool: {
            filter: Filter[];
        };
    };
    sort?: SortBy[];
}

export interface ItemsBySlugsQuery extends ThemeQuery {
    query: {
        bool: {
            filter: [SlugsFilter];
        };
    };
}

export function itemsBySlugsQuery(slugs: string[]): ItemsBySlugsQuery {
    return {
        from: 0,
        size: slugs.length,
        query: {
            bool: {
                filter: [filterBySlugs(slugs)],
            },
        },
    };
}

export function fromCatalogQueryFiltersToUrlSearch(
    filters: CatalogPrimaryFilters | undefined,
): string | undefined {
    if (!filters) {
        return undefined;
    }

    const categories = filters.offeringCategories
        ? filters.offeringCategories
              ?.map((it, index) =>
                  encodeKeyValuePair(`offeringCategories[${index}]`, it),
              )
              .join("&")
        : undefined;

    const locationSearchTerm = filters.locationSearchTerm
        ? encodeKeyValuePair("locationSearchTerm", filters.locationSearchTerm)
        : undefined;

    const location = filters.location
        ? Object.entries(filters.location)
              .filter(([_key, value]) => !!value)
              .map(([key, value]) => {
                  if (!value) return undefined;
                  const stringOrNumber =
                      value && isString(value) ? value : value.toString();
                  return stringOrNumber && stringOrNumber.length !== 0
                      ? encodeKeyValuePair(key, stringOrNumber)
                      : undefined;
              })
              .filter(excludeUndefined)
              .join("&")
        : undefined;

    const searchTerms = [categories, locationSearchTerm, location]
        .filter(excludeUndefined)
        .join("&");

    return searchTerms.length !== 0 ? searchTerms : undefined;
}
export interface CityGuideItemsQuery extends OpenSearchQuery {
    query: {
        bool: {
            must: MustPublishedFilter;
            filter: [CityGuideLocationFilter, AlbertineVerifiedFilter];
        };
    };
    sort: [SortByAlbertineVerified];
}

export function cityGuideQuery(location: string): CityGuideItemsQuery {
    return {
        from: "$from",
        size: "$size",
        query: {
            bool: {
                must: mustPublishedFilter,
                filter: [
                    filterByCityGuideLocation([location]),
                    filterByAlbertineVerified,
                ],
            },
        },
        sort: [sortByAlbertineVerified],
    };
}

export interface NewRestaurantsQuery extends OpenSearchQuery {
    query: {
        bool: {
            must: MustPublishedFilter;
            filter: [
                CityGuideLocationFilter,
                { terms: { offeringCategories: ["dining"] } },
                RecentlyOpenedFilter,
            ];
        };
    };
    sort: [SortByRecentlyOpened];
}

export function newRestaurantsQuery(location: string): NewRestaurantsQuery {
    return {
        from: "$from",
        size: "$size",
        query: {
            bool: {
                must: mustPublishedFilter,
                filter: [
                    filterByCityGuideLocation([location]),
                    { terms: { offeringCategories: ["dining"] } },
                    filterByRecentlyOpened,
                ],
            },
        },
        sort: [sortByRecentlyOpened],
    };
}

export interface LocalEventsQuery extends OpenSearchQuery {
    query: {
        bool: {
            must: MustPublishedFilter;
            filter: [
                CityGuideLocationFilter,
                { terms: { offeringCategories: ["event"] } },
            ];
        };
    };
    sort: [SortByPublished];
}

export function localEventsQuery(location: string): LocalEventsQuery {
    return {
        from: "$from",
        size: "$size",
        query: {
            bool: {
                must: mustPublishedFilter,
                filter: [
                    filterByCityGuideLocation([location]),
                    { terms: { offeringCategories: ["event"] } },
                ],
            },
        },
        sort: [sortByPublished],
    };
}

export interface OfferingCategoriesQuery extends OpenSearchQuery {
    query: {
        bool: {
            must: MustPublishedFilter;
            filter: [OfferingCategoriesFilter];
        };
    };
    sort: [SortByPublished];
}

export function offeringCategoriesQuery(
    offeringCategories: OfferingCategory[],
): OfferingCategoriesQuery {
    return {
        from: "$from",
        size: "$size",
        query: {
            bool: {
                must: mustPublishedFilter,
                filter: [filterByCategories(offeringCategories)],
            },
        },
        sort: [sortByPublished],
    };
}

export function findTranslations(
    item: OpenSearchCatalogHitSource,
    serviceLanguage: ServiceLanguage,
): CatalogItemTranslation & { name: string; keyDetail: string } {
    const englishBaseline = {
        address: item.address,
        benefits: item.benefits,
        bookingPolicy: item.bookingPolicy,
        description: item.description,
        eventDates: item.eventDates,
        headline: item.headline,
        localInsights: item.localInsights || null,
        name: item.name,
        travelItineraryNotes: item.travelItineraryNotes,
        keyDetail: item.keyDetail || "",
    };

    if (serviceLanguage === "en") {
        return englishBaseline;
    }

    const translation = item?.translations?.[serviceLanguage] || undefined;

    return translation
        ? {
              ...translation,
              name: translation.name || englishBaseline.name,
              keyDetail: translation.keyDetail || englishBaseline.keyDetail,
          }
        : englishBaseline;
}
