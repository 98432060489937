import z from "zod";

export const OnboardingSteps = z.union([
    z.literal("conciergeExperience"),
    z.literal("start"),
    z.literal("name"),
    z.literal("cities"),
    z.literal("diningPlans"),
    z.literal("travelPlans"),
]);
export type OnboardingSteps = z.infer<typeof OnboardingSteps>;
