import { Lib, useAlbertineTranslation } from "albertine-shared-web";
import React, { Fragment, useEffect, useRef, useState } from "react";
import "./Chat.css";
import { v4 as uuid } from "uuid";
import { useLocation } from "react-router";
import { isSameDay } from "date-fns";
import {
    MemberSendMessagePayload,
    Message,
} from "../../../lmt/src/common/types/Message";
import { fromTimestampToDate } from "../utils/timestamp.util";
import { ListenToMessagesPaged } from "../loaders/ListenToConversationMessages";
import {
    memberMarkConversationMessagesRead,
    memberSendMessage,
} from "../api/firestore";
import Avatar from "../components/Avatar";
import { ChatMessage, messagesToChatMessages } from "../utils/chatmessage.util";
import { useScreenStack } from "../context/screenStack";
import { Conversation } from "../../../lmt/src/common/types/Conversation";
import {
    quickRequestQuestionAnswerPairs,
    quickRequestSubtitle,
    quickRequestTag,
    quickRequestTitle,
} from "../../../lmt/src/common/utils/quickRequests.util";
import {
    removeLocalChatMessageBySendingId,
    updateLocalChatMessage,
    upsertLocalChatMessage,
} from "../utils/localStorage.messages.util";
import { useLocalChatMessages } from "../utils/localStorage.listeners.util";
import Loading from "../components/Loading";
import { MemberRequest } from "../../../lmt/src/common/types/Request";
import ListenToRequests from "../loaders/ListenToRequests";
import { conversationHasUnreadMessages } from "../utils/threads.util";
import { logError } from "../error";

type ChatProps = {
    currentMemberId: string;
    conversationId: string;
    conversation: Conversation;
    requestId: string | undefined;
    requestCreatedFromMessage: string | undefined;
};

function messageToMemberSendMessagePayload(
    message: string,
    request?: string,
): MemberSendMessagePayload {
    return {
        sendingId: uuid(),
        message: message.trim(),
        request,
    };
}

const chatLoadMoreButtonId = "chat__chat-messages-container__load-more-button";

function ChatMessageComponent(props: {
    currentMemberId: string;
    chatMessage: ChatMessage;
    displayTopic: boolean;
    request: MemberRequest | undefined;
}) {
    const { chatMessage, currentMemberId, displayTopic, request } = props;
    const { message, showSenderRow } = chatMessage;

    const t = useAlbertineTranslation();
    const { openChat, openBooking, openArticle, openProposal } =
        useScreenStack();

    const displayMessageTopic = displayTopic && !!request;

    const isSender = message.sentBy === currentMemberId;
    const messageHtml = message.messageHtml || undefined;
    const sentAt = fromTimestampToDate(message.sentAt);
    const requestTitle = request?.title || message.requestTitle || undefined;
    const requestId = request?.id || message.request || undefined;
    const topic =
        displayMessageTopic && requestTitle
            ? {
                  title: requestTitle,
                  onClick: () => {
                      if (requestId) {
                          openChat(requestId);
                      }
                  },
              }
            : undefined;
    const addExtraSpacing = showSenderRow
        ? "chat__message__additional-spacing"
        : undefined;
    const attachments = message.attachments?.map((attachment) => ({
        filename: attachment.filename,
        mimeType: attachment.mimeType,
        url: attachment.url,
        onClick: () => {
            window.open(attachment.url, "_blank");
        },
    }));

    const hasBookingSuggestions =
        message.bookingSuggestions && message.bookingSuggestions.length > 0;
    const everyBookingSuggestionIsConfirmed =
        message.bookingSuggestions?.every(
            (booking) => booking.reservationStatus === "confirmed",
        ) || false;

    const confirmedBookingSuggestions =
        hasBookingSuggestions && everyBookingSuggestionIsConfirmed
            ? message.bookingSuggestions
            : undefined;
    const proposalBookingSuggestions =
        hasBookingSuggestions && !everyBookingSuggestionIsConfirmed
            ? message.bookingSuggestions
            : undefined;

    const confirmedBookings = [
        ...(message.confirmedBooking ? [message.confirmedBooking] : []),
        ...(confirmedBookingSuggestions || []),
    ].map((booking) => ({
        id: booking.id,
        title: booking.title,
        imageUrl: booking?.croppedImageURL || booking?.imageURL,
        onClick: () => {
            openBooking(booking.id);
        },
    }));

    const proposal =
        hasBookingSuggestions &&
        proposalBookingSuggestions &&
        proposalBookingSuggestions.length > 0
            ? {
                  id: message.request,
                  title: message.requestTitle,
                  imageUrls: proposalBookingSuggestions.map(
                      (booking) =>
                          booking?.croppedImageURL || booking?.imageURL,
                  ),
                  onClick: () => {
                      if (message.request) openProposal(message.request);
                  },
              }
            : undefined;

    const label = (() => {
        if (proposal) return t("chat__proposal-title");
        if (confirmedBookings && confirmedBookings.length > 0)
            return t("chat__confirmed-booking-title");
        if (message.quickRequest) return quickRequestTag(message.quickRequest);
        return undefined;
    })();

    const reply =
        message.replyToMessageId && message.replyToMessage
            ? {
                  id: message.replyToMessageId,
                  title: t("chat__reply_title"),
                  message: message.replyToMessage.message,
                  imageUrl:
                      message.confirmedBooking?.croppedImageURL ||
                      message.confirmedBooking?.imageURL ||
                      message.replyToMessage.attachments?.filter((attachment) =>
                          attachment.mimeType.startsWith("image/"),
                      )[0]?.url,
                  onClick: () => {
                      // TODO: Implement reply to message
                      console.warn("Reply to message not implemented");
                  },
              }
            : undefined;

    const articlesV1 = (message.articles || []).map((article) => ({
        id: article.uuid,
        title: article.content.title,
        imageUrl: article.content.coverImage?.filename,
        onClick: () => {}, // deprecated - won't be implemented
    }));

    const articlesV2 = (message.articlesV2 || []).map((article) => ({
        id: article.id,
        title: article.name,
        subtitle: article.keyDetail,
        imageUrl: article.image,
        onClick: () => {
            openArticle(article.id);
        },
    }));

    const quickRequest = message.quickRequest
        ? {
              title: quickRequestTitle(message.quickRequest),
              subtitle: quickRequestSubtitle(message.quickRequest),
              questionAnswerPairs: quickRequestQuestionAnswerPairs(
                  message.quickRequest,
              ),
          }
        : undefined;

    const articles = [...articlesV1, ...articlesV2];

    if (isSender) {
        return (
            <Lib.ChatMessage.Member
                id={message.id}
                key={message.id}
                className={addExtraSpacing}
                message={message.message}
                messageHtml={messageHtml}
                sentAt={sentAt}
                topic={topic}
                label={label}
                showSenderRow={showSenderRow}
                attachments={attachments}
                confirmedBookings={confirmedBookings}
                proposal={proposal}
                reply={reply}
                articles={articles}
                quickRequest={quickRequest}
            />
        );
    }
    return (
        <Lib.ChatMessage.Agent
            id={message.id}
            key={message.id}
            avatar={
                <Avatar.Agent.Small
                    id={message.sentBy}
                    fullName={message.senderName}
                />
            }
            className={addExtraSpacing}
            message={message.message}
            messageHtml={messageHtml}
            senderName={message.senderName}
            sentAt={sentAt}
            topic={topic}
            label={label}
            showSenderRow={showSenderRow}
            attachments={attachments}
            confirmedBookings={confirmedBookings}
            proposal={proposal}
            reply={reply}
            articles={articles}
        />
    );
}

function LocalChatMessages(props: {
    sendMessage: (messagePayload: MemberSendMessagePayload) => Promise<void>;
    requestId: string | undefined;
}) {
    const { sendMessage, requestId } = props;
    const localChatMessages = useLocalChatMessages()?.filter(
        (it) => it.message.request === requestId,
    );
    if (!localChatMessages) return null;
    if (localChatMessages.length === 0) return null;
    return (
        <div className="chat__group__messages chat__group__local-messages">
            {localChatMessages
                ?.reverse()
                .map((localMessage) => ({
                    localMessage,
                    messageComponent: (
                        <Lib.ChatMessage.Member
                            id={localMessage.message.sendingId}
                            key={localMessage.message.sendingId}
                            message={localMessage.message.message}
                            sendingStatus={localMessage.sendingStatus}
                            sentAt={new Date()}
                        />
                    ),
                }))
                .map((tuple) =>
                    tuple.localMessage.sendingStatus === "failed" ? (
                        <Lib.Button.Ghost
                            key={tuple.localMessage.message.sendingId}
                            onClick={() => {
                                sendMessage(tuple.localMessage.message);
                            }}
                        >
                            {tuple.messageComponent}
                        </Lib.Button.Ghost>
                    ) : (
                        tuple.messageComponent
                    ),
                )}
        </div>
    );
}

function ChatMessagesContainer(props: {
    currentMemberId: string;
    conversation: Conversation;
    requestId: string | undefined;
    messages: Message[];
    isLoading: boolean;
    memberRequests: MemberRequest[];
    loadMore: (() => Promise<void>) | undefined;
}) {
    const {
        currentMemberId,
        conversation,
        requestId,
        messages,
        isLoading,
        memberRequests,
        loadMore,
    } = props;

    const displayTopic = !requestId;
    const getMessageRequest = (message: Message): MemberRequest | undefined =>
        memberRequests.find((it) => it.id === message.request) ||
        memberRequests.find((it) => it.createdFromMessage === message.id);

    const t = useAlbertineTranslation();

    let captureSentAtDate: Date | undefined;
    const showChatMessagePill = (message: Message) => {
        const sentAtDate = fromTimestampToDate(message.sentAt);
        const previousSentAtDate = captureSentAtDate;
        captureSentAtDate = sentAtDate;

        if (!previousSentAtDate) {
            return true;
        }
        if (isSameDay(previousSentAtDate, sentAtDate)) {
            return false;
        }
        return true;
    };

    const [unreadMessages, setUnreadMessages] = useState(
        conversation.unreadMessagesByMember || [],
    );

    useEffect(() => {
        if (!conversation.unreadMessagesByMember) return;
        if (conversation.unreadMessagesByMember.length > 0) {
            setUnreadMessages(unreadMessages);
        }
    }, [unreadMessages, conversation.unreadMessagesByMember]);

    const unreadMessagesThreaded = unreadMessages.filter(
        (message) => message.request === (requestId ?? null),
    );
    const displayNewMessagesTagId = (message: Message) =>
        unreadMessagesThreaded[0]?.message === message.id;

    return (
        <div className="chat__chat-messages-container">
            <Lib.Flex.Row
                justifyContent="center"
                className="chat__chat-messages-container__load-more"
            >
                {!isLoading && loadMore && (
                    <Lib.Flex.Row justifyContent="center">
                        <Lib.Button.Ghost onClick={loadMore}>
                            <div
                                id={chatLoadMoreButtonId}
                                className="chat__chat-messages-container__load-more-button"
                            >
                                <Lib.Label.Medium.MediumBold.Highlight01>
                                    {t("chat__load_more")}
                                </Lib.Label.Medium.MediumBold.Highlight01>
                            </div>
                        </Lib.Button.Ghost>
                    </Lib.Flex.Row>
                )}
                {isLoading && <Loading />}
            </Lib.Flex.Row>
            {messagesToChatMessages(messages)
                .reverse()
                .map((chatMessage) => (
                    <Fragment key={chatMessage.message.id}>
                        {showChatMessagePill(chatMessage.message) && (
                            <Lib.ChatMessagePill
                                text={Lib.Utils.TextFormatter.chat.datePill(
                                    fromTimestampToDate(
                                        chatMessage.message.sentAt,
                                    ),
                                )}
                            />
                        )}
                        {displayNewMessagesTagId(chatMessage.message) && (
                            <Lib.NewMessagesLabel />
                        )}
                        <ChatMessageComponent
                            currentMemberId={currentMemberId}
                            chatMessage={chatMessage}
                            displayTopic={displayTopic}
                            request={getMessageRequest(chatMessage.message)}
                        />
                    </Fragment>
                ))}
        </div>
    );
}

function ChatMessagesPagination(props: {
    conversationId: string;
    conversation: Conversation;
    requestId: string | undefined;
    currentMemberId: string;
    requestCreatedFromMessage: string | undefined;
}) {
    const {
        conversationId,
        conversation,
        requestId,
        currentMemberId,
        requestCreatedFromMessage,
    } = props;

    return (
        <ListenToRequests
            memberId={currentMemberId}
            isLoading={
                <Lib.Flex.Row className="chat__loading-spinner">
                    <Loading />
                </Lib.Flex.Row>
            }
        >
            {(memberRequests) => (
                <ListenToMessagesPaged
                    conversationId={conversationId}
                    requestId={requestId}
                    requestCreatedFromMessage={requestCreatedFromMessage}
                >
                    {(messages, isLoading, loadMore) => (
                        <ChatMessagesContainer
                            currentMemberId={currentMemberId}
                            conversation={conversation}
                            requestId={requestId}
                            messages={messages}
                            isLoading={isLoading}
                            memberRequests={memberRequests}
                            loadMore={loadMore}
                        />
                    )}
                </ListenToMessagesPaged>
            )}
        </ListenToRequests>
    );
}

function Chat(props: ChatProps) {
    const {
        currentMemberId,
        conversationId,
        conversation,
        requestId,
        requestCreatedFromMessage,
    } = props;

    const t = useAlbertineTranslation();
    const location = useLocation();
    const isActive = location.state?.focus;
    const chatWrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!chatWrapperRef.current) return undefined;
        const chatWrapper = chatWrapperRef.current;
        const onScroll = () => {
            const topOffset = 100;
            const scrollTop =
                chatWrapper.scrollHeight - chatWrapper.clientHeight;
            const hasScrolledToTop =
                Math.abs(chatWrapper.scrollTop) > scrollTop - topOffset;
            if (hasScrolledToTop) {
                const loadMoreButton =
                    document.getElementById(chatLoadMoreButtonId);
                if (loadMoreButton) {
                    loadMoreButton.click();
                }
            }
        };
        chatWrapper.addEventListener("scroll", onScroll);
        return () => chatWrapper.removeEventListener("scroll", onScroll);
    }, [chatWrapperRef]);

    const sendMessage = async (messagePayload: MemberSendMessagePayload) => {
        upsertLocalChatMessage({
            message: messagePayload,
            sendingStatus: "sending",
        });
        try {
            await memberSendMessage(messagePayload);
            removeLocalChatMessageBySendingId(messagePayload.sendingId);
        } catch (e) {
            logError("sendMessage", e);
            updateLocalChatMessage({
                message: messagePayload,
                sendingStatus: "failed",
            });
        }
    };

    useEffect(() => {
        if (conversationHasUnreadMessages(conversation, requestId)) {
            memberMarkConversationMessagesRead({
                conversationId,
                requestId,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Lib.Flex.Column className="chat" key={conversationId + requestId}>
            <Lib.ChatMessage.Wrapper ref={chatWrapperRef}>
                <LocalChatMessages
                    sendMessage={sendMessage}
                    requestId={requestId}
                />
                <ChatMessagesPagination
                    conversationId={conversationId}
                    conversation={conversation}
                    requestId={requestId}
                    currentMemberId={currentMemberId}
                    requestCreatedFromMessage={requestCreatedFromMessage}
                />
            </Lib.ChatMessage.Wrapper>
            <Lib.ChatInput
                placeholder={t("chat_input__placeholder")}
                onSend={async (message: string) => {
                    const payload = messageToMemberSendMessagePayload(
                        message,
                        requestId,
                    );
                    await sendMessage(payload);
                    return true;
                }}
                isActive={isActive}
            />
        </Lib.Flex.Column>
    );
}

export default Chat;
