import { useEffect, useState } from "react";
import { listenToQueryToState } from "../api/listeners";
import { queryMemberRequests } from "../api/firestore";
import { MemberRequest } from "../../../lmt/src/common/types/Request";

interface Props {
    children: (requests: MemberRequest[]) => JSX.Element;
    isLoading?: JSX.Element;
}

export default function ListenToRequests(props: { memberId: string } & Props) {
    const { memberId, children, isLoading } = props;
    const [requests, setRequests] = useState<MemberRequest[]>();

    useEffect(() => {
        if (!memberId) {
            setRequests(undefined);
            return undefined;
        }
        return listenToQueryToState<MemberRequest>(
            queryMemberRequests(memberId),
            setRequests,
        );
    }, [memberId]);

    if (requests === undefined) return isLoading || null;
    return children(requests);
}
