/* eslint-disable react/destructuring-assignment */
import React, { Component, PropsWithChildren } from "react";
import { httpsCallable } from "firebase/functions";
import { Tenant, useTenant } from "albertine-shared-web";
import { functions } from "./Firebase";
import retry from "./utils/retry.util";

let logErrorTenant: Tenant;

/**
 * Set the tenant to use for logging errors. Call this only once and at the start of the app.
 *
 * @param newTenant The tenant ID.
 */
export function setupLogErrorTenant(newTenant: Tenant) {
    if (logErrorTenant) {
        console.warn("Tenant already set, overwriting");
    }

    logErrorTenant = newTenant;
}

// Needed because we want to log all errors to console when calling logError
// but we don't want to log errors twice in case the the global error listeners
// are added (which proxy the console.error calls to logError).
const originalConsoleError = console.error;

/**
 * Log an error to the server. Will be automatically retried if it fails, doesn't do any batching.
 *
 * Remember to call `setupLogErrorTenant` before calling this function.
 *
 * @param eventSource A human understandable description of where the error came from.
 * For example, "onerror", "onunhandledrejection", "console.error", "console.warn", "ReportingObserver", or "ErrorBoundary".
 * @param error The error to log, can be anything, usually an Error object.
 */
export async function logError(
    eventSource: string,
    error: unknown,
    opts?: { console?: boolean; tenant?: Tenant },
): Promise<void> {
    const tenant = opts?.tenant ?? logErrorTenant;

    if (opts?.console ?? true) {
        originalConsoleError("Logging error", {
            tenant,
            eventSource,
            error,
        });
    }

    await retry(
        () =>
            httpsCallable<
                { tenant: string; eventSource: string; error: unknown },
                unknown
            >(functions, "logError", { timeout: 5 * 1000 })({
                tenant,
                eventSource,
                error,
            }),
        {},
    );
}

/**
 * Add global listeners for unhandled errors and log them to the server.
 * Must only be called once.
 *
 * Remember to call `setupLogErrorTenant` before calling this function.
 */
export function registerGlobalErrorListeners() {
    window.addEventListener("error", (event) => {
        logError("onerror", event.error);
    });

    window.addEventListener("unhandledrejection", (event) => {
        logError("onunhandledrejection", event.reason);
    });

    console.error = function consoleErrorOverload(...args: unknown[]) {
        logError("console.error", args, { console: false });
        originalConsoleError.apply(console, args);
    };

    const originalWarn = console.warn;
    console.warn = function consoleWarnOverload(...args: unknown[]) {
        logError("console.warn", args, { console: false });
        originalWarn.apply(console, args);
    };

    if ("ReportingObserver" in window) {
        const observer = new ReportingObserver(
            (reports) => {
                reports.forEach((report) => {
                    logError("ReportingObserver", report);
                });
            },
            { buffered: true, types: ["deprecation", "intervention", "crash"] },
        );

        observer.observe();
    }
}

type ErrorBoundaryInnerProps = PropsWithChildren<{
    resetErrorRef?: React.MutableRefObject<() => void>;
    tenant: Tenant;
    fallback: React.ReactNode;
}>;

class ErrorBoundaryInner extends Component<
    ErrorBoundaryInnerProps,
    { hasError: boolean }
> {
    constructor(props: ErrorBoundaryInnerProps) {
        super(props);
        this.state = { hasError: false };

        if (this.props.resetErrorRef) {
            this.props.resetErrorRef.current = () => {
                this.setState({ hasError: false });
            };
        }
    }

    componentDidUpdate(
        prevProps: Readonly<ErrorBoundaryInnerProps>,
        _prevState: Readonly<{ hasError: boolean }>,
    ): void {
        if (prevProps.tenant !== this.props.tenant) {
            this.setState({ hasError: false });
        }

        if (
            this.props.resetErrorRef &&
            prevProps.resetErrorRef !== this.props.resetErrorRef
        ) {
            this.props.resetErrorRef.current = () => {
                this.setState({ hasError: false });
            };
        }
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: unknown, errorInfo: unknown) {
        logError(
            "ErrorBoundary",
            { error, errorInfo },
            { tenant: this.props.tenant },
        )
            .then(() => {
                console.log("Logged error successfully");
            })
            .catch((e) => {
                console.error("Failed to log error", e);
            });
    }

    render() {
        if (this.state.hasError) {
            return this.props.fallback;
        }

        return this.props.children;
    }
}

export type ErrorBoundaryProps = PropsWithChildren<{
    /*
     * Ref to function that can be called to reset the error boundary.
     *
     * ```tsx
     * const resetErrorRef = React.useRef<() => void>(() => {});
     * ```
     */
    resetErrorRef?: React.MutableRefObject<() => void>;
    fallback: React.ReactNode;
}>;

export function ErrorBoundary({
    resetErrorRef,
    fallback,
    children,
}: ErrorBoundaryProps) {
    const { tenant } = useTenant();
    return (
        <ErrorBoundaryInner
            resetErrorRef={resetErrorRef}
            tenant={tenant}
            fallback={fallback}
        >
            {children}
        </ErrorBoundaryInner>
    );
}
