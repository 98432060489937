import React from "react";
import { SwiperOptions } from "swiper/types";
import { Navigation } from "swiper/modules";
import { Swiper } from "swiper/react";
import "swiper/css";
import "./AlbertineSwiper.css";
import { Lib } from "albertine-shared-web";

interface AlbertineSwiperProps {
    children?: React.ReactNode;
    className?: string;
    spaceBetween?: number;
    breakpoints?:
        | {
              [p: number]: SwiperOptions;
              [p: string]: SwiperOptions;
          }
        | undefined;
}

function AlbertineSwiper(props: AlbertineSwiperProps) {
    const { children, className, spaceBetween, breakpoints } = props;
    return (
        <Swiper
            slidesPerView="auto"
            spaceBetween={spaceBetween || 0}
            effect="fade"
            breakpoints={breakpoints || undefined}
            navigation={{
                prevEl: ".web-lib__albertine-swiper__arrow-left",
                nextEl: ".web-lib__albertine-swiper__arrow-right",
                disabledClass:
                    "web-lib__button web-lib__albertine-swiper__disabled-arrow",
            }}
            modules={[Navigation]}
            className={className}
        >
            {children}

            <Lib.Button.Ghost className="web-lib__albertine-swiper__arrow web-lib__albertine-swiper__arrow-left">
                <Lib.Icon.ChevronLeft.Large.Gray10 className="web-lib__albertine-swiper__arrow-icon" />
            </Lib.Button.Ghost>

            <Lib.Button.Ghost className="web-lib__albertine-swiper__arrow web-lib__albertine-swiper__arrow-right">
                <Lib.Icon.ChevronRight.Large.Gray10 className="web-lib__albertine-swiper__arrow-icon" />
            </Lib.Button.Ghost>
        </Swiper>
    );
}

export default AlbertineSwiper;
